// EditorSwiper.js
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../css/SwiperEditor.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import { FreeMode, Scrollbar } from "swiper";
import donald from "images/donald.webp";
import plus from "images/Plus.webp";
import PresetMenu from "./PresetMenus/PresetMenuEditor.js";
import LockPresetMenu from "./PresetMenus/LockPresetMenuEditor.js";
import x from "../images/xcircle.webp";
import useGetUserInfo from "hooks/useUserInfo.js";

export default function SwiperEditor({
  typing,
  setSelectedOptions,
  onElementClick,
  selectedOptions,
  onDeleteButtonClick,
}) {
  const { accountTier } = useGetUserInfo();
  const [slideIndex, setSlideIndex] = useState(0);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [showPresetMenu, setShowPresetMenu] = useState(false);
  const [isFirstVoiceAdded, setIsFirstVoiceAdded] = useState(false);
  const [selectedNameAndIndex, setSelectedNameAndIndex] = useState({
    name: "",
    index: 0,
  });
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenuVisibility = () => {
    //console.log("Toggling menu visibility");
    setIsMenuVisible((prevState) => {
      const updatedState = !prevState;
      //console.log("Toggling menu visibility", updatedState);
      return updatedState;
    });
  };

  const handleButtonClick = (event) => {
    //console.log("PLUS button clicked");
    event.stopPropagation();
    toggleMenuVisibility();
    const buttonElement = event.currentTarget;
    const slideElement = buttonElement.closest(".timeline-NodeContainer");
    const currentIndex = Array.from(
      slideElement.parentElement.children
    ).indexOf(slideElement);

    setSlideIndex(currentIndex + 1);
  };

  const initialSlides = Array.from({ length: 1 }, (_, i) => ({
    option: null,
    picture: null,
    button: (
      <img
        key={i}
        src={plus}
        alt="Add a voice"
        type="button"
        className="timeline-addVoiceButton1"
        onClick={(event) => {
          setSlideIndex(i + 1);
          handleButtonClick(event); // Add this line to update the menu visibility
        }}
      />
    ),
    text: <p className="timeline-text"> &#8592; Add a voice</p>,
  }));

  const [slides, setSlides] = useState(initialSlides);

  const addSlide = (option, presetImg, currentIndex) => {
    const newSlide = {
      option,
      picture: presetImg,
      button: (
        <img
          src={plus}
          alt="Add Voice"
          className="timeline-addVoiceButton"
          type="button"
          onClick={(event) => {
            handleButtonClick(event);
          }}
        />
      ),
      text: null,
    };

    setSlides((prevSlides) => {
      const updatedSlides = [
        ...prevSlides.slice(
          0,
          isFirstVoiceAdded ? currentIndex : currentIndex - 1
        ),
        newSlide,
        ...prevSlides.slice(currentIndex + (isFirstVoiceAdded ? 0 : 1)),
      ];

      setSelectedOptions((prevOptions) => {
        const updatedOptions = [
          ...prevOptions.slice(0, currentIndex),
          { option, index: currentIndex, TextInput: null }, // Add TextInput: null
          ...prevOptions.slice(currentIndex),
        ];
        return updatedOptions;
      });

      return updatedSlides;
    });
  };

  const handlePresetSelect = (option, presetImg) => {
    setSelectedNameAndIndex({ option, index: slideIndex });
    setShowPresetMenu(false);
    addSlide(option, presetImg, slideIndex);
    if (!isFirstVoiceAdded) {
      setIsFirstVoiceAdded(true);
    }
  };

  const logAllSlides = () => {
    slides.forEach((slide, index) => {
      if (slide.option !== null) {
        console.log({ option: slide.option, index });
      }
    });
  };
  const handleElementClick = (index) => {
    setSelectedImageIndex(index);
    onElementClick(index); // Add this line
  };
  const deleteSlide = (index) => {
    if (index === -1) return;

    setSlides((prevSlides) => {
      const updatedSlides = [...prevSlides];
      updatedSlides.splice(index, 1);

      // Check if all slides have been deleted
      if (updatedSlides.every((slide) => slide.picture === null)) {
        // Reset the state to the initial state
        setIsFirstVoiceAdded(false);
        return initialSlides;
      }

      return updatedSlides;
    });

    setSelectedOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions.splice(index, 1);
      return updatedOptions;
    });

    // Reset selectedImageIndex if the deleted slide was selected
    if (selectedImageIndex === index) {
      setSelectedImageIndex(-1);
    }
  };

  return (
    <div className="timeline-Container">
      {isMenuVisible ? (
        accountTier === "Free" ? (
          <LockPresetMenu
            handleSelect={handlePresetSelect}
            closeMenu={toggleMenuVisibility}
            className={`PresetMenu-container ${
              isMenuVisible ? "PresetMenu-container-visible" : ""
            }`}
          />
        ) : (
          <PresetMenu
            handleSelect={handlePresetSelect}
            closeMenu={toggleMenuVisibility}
            className={`PresetMenu-container ${
              isMenuVisible ? "PresetMenu-container-visible" : ""
            }`}
          />
        )
      ) : null}
      <Swiper
        slidesPerView={2.4}
        spaceBetween={50}
        freeMode={false}
        hide={false}
        centeredSlides={true}
        modules={[FreeMode, Scrollbar]}
        breakpoints={{
          1024: {
            slidesPerView: 4.8,
          },
        }}
      >
        {slides.map((slide, i) => {
          const isGray =
            !selectedOptions[i] ||
            !selectedOptions[i].TextInput ||
            selectedOptions[i].TextInput.trim() === "";
          return (
            <SwiperSlide key={i} className="timeline-NodeContainer">
              {slide.picture ? (
                <div
                  className={`timeline-imageContainer ${
                    selectedImageIndex === i ? "selected" : ""
                  }`}
                  onClick={() => handleElementClick(i)}
                >
                  <button
                    type="button"
                    className="timeline-deleteButtonContainer"
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteSlide(i);
                    }}
                  >
                    <img src={x} className="timeline-deleteButton" />
                  </button>

                  <img
                    src={slide.picture}
                    alt="Selected Candidate"
                    className={`timeline-presetPicture ${isGray ? "gray" : ""}`}
                  />
                </div>
              ) : (
                <div className="timeline-nameContainer"></div>
              )}
              {slide.button}
              {slide.text}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
