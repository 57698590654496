//TopbarLI.js
import React, { useState } from "react";
import "css/main.css";
import "css/topBar.css";
import "css/BurgerMenu.css"
import logotext from "../images/logo.png";
import logoObject from "../images/logoObject.png";
import User from "../images/user.webp";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import pb from "UIM";
import ClickedOnCelebMix from "js/celeb-mix-analytics.js";

export default function Topbar() {

  function BurgerMenu() {
    return (
      <Menu className="bm-menu">
        <Link className="menu-item" to="/pricing">
          Pricing
        </Link>
        <Link onClick={ClickedOnCelebMix} className="menu-item" to="/celebmix">
          Celeb-Mix
        </Link>
        <Link onClick={ClickedOnCelebMix} className="menu-item" to="/voicelab">
          Voice Lab
        </Link>
      </Menu>
    );
  }
  function LogOut() {
    // should be removed and moved to home page, this is just for testing
    pb.authStore.clear();
    window.location.href = "/";
  }



  return (
    <div className="topbarContiner">
      <div className="topbar">
        <div className="topbar-containerSignIn">
          <BurgerMenu />
          <Link to="/pricing">
            <button className="topbar-pricingButton">Pricing</button>
          </Link>
          <Link to="/celebmix">
            <button onClick={ClickedOnCelebMix} className="topbar-pricingButton">Celeb-Mix</button>
          </Link>
          <Link to="/voicelab">
            <button onClick={ClickedOnCelebMix} className="topbar-pricingButton">Voice Lab</button>
          </Link>
        </div>


        <Link className="topbar-containerLogo" to="/">
          <img src={logotext} style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </Link>
        <Link className="SMtopbar-containerLogo" to="/">
          <img
            src={logoObject}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Link>
        <div className="topbar-containerSignUp">
          <Link to={"/user"} className="topbar-User">
            <img src={User} style={{ maxWidth: "100%", maxHeight: "100%" }} />
          </Link>
        </div>
      </div>
    </div>
  );
}
