import { marVoiceMap, daveVoiceMap } from "./voiceIDs.js";

function newGetVoiceID(voiceName) {
  let returnArray = [];
  if (Object.keys(marVoiceMap).includes(voiceName)) {
    returnArray.push(marVoiceMap[voiceName]);
    returnArray.push(process.env.REACT_APP_MAR_ELEVEN_LABS_API_KEY);
    return returnArray;
  } else if (Object.keys(daveVoiceMap).includes(voiceName)) {
    returnArray.push(daveVoiceMap[voiceName]);
    returnArray.push(process.env.REACT_APP_DAVE_ELEVEN_LABS_API_KEY);
    return returnArray;
  } else {
    console.error(
      "newGetVoiceID.js, third else statement. there is no matching name in either map"
    );
  }
}
export { newGetVoiceID };
