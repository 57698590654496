import React, { useState, useEffect } from "react";
import "../css/home.css";
import "../css/signupin.css";
import LogoObject from "images/logoObject.png";
import { Link } from "react-router-dom";
import Footer from "hooks/Footer";

export default function Guidelines() {
  return (
    <div className="mainWrapper">
      <Link to="/" className="signInOutTopbar">
        <img src={LogoObject} className="signInOutTopbarLogo"></img>
      </Link>
      <div style={{ color: "white" }}>
        All audio files generated using our service are intended for personal
        use only. You may use the generated audio files for your own personal
        entertainment, educational purposes, or personal projects. We do not
        endorse or support the distribution, sharing, or public display of the
        generated audio files. It is strictly prohibited to use the generated
        audio files for any commercial purposes, including but not limited to
        selling, licensing, or distributing them for financial gain. You are
        solely responsible for any consequences that may arise from any
        unauthorized distribution or sharing of the generated audio files. We
        will not take any action to monitor or prevent the distribution of the
        generated audio files, but we are not liable for any misuse or
        unauthorized use of the files by others. If you choose to share the
        generated audio files with others, it is your responsibility to ensure
        that you have the necessary rights and permissions to do so. We
        recommend that you respect the intellectual property rights of others
        and refrain from using the generated audio files in a way that infringes
        upon any copyright, trademark, or other proprietary rights. By using our
        service, you acknowledge and agree to abide by these guidelines for
        personal use of the generated audio files.
      </div>
      <Footer />
    </div>
  );
}
