import countries from "../../countriesList";
import 'css/stripe.css'
import dropdownSVG from '../../images/cart/dropdown.png'

const BillingAddressComtainer = (props) => {
  return (
    <div className="flex flex-col relative">
      {props.errors.address && (
        <span
          className="absolute right-0"
          style={{
            color: "rgb(220, 39, 39) ",
            fontSize: "11px",
            fontWeight: "500",
            fontFamily: "segoeui-semibold",
          }}
        >
          REQUIRED
        </span>
      )}
      <label
        className="pb-1"
        style={{
          fontSize: "13px",
          fontWeight: "500",
          color: "rgba(97,97,97, 0.85)",
          fontFamily: "segoeui-semibold",
        }}
      >
        Billing Address
      </label>
      <img
        src={dropdownSVG}
        className="absolute right-0 z-50"
        style={{
          cursor: "pointer",
          top: "37%",
          width: "14px",
          marginRight: "10px",
        }}
      ></img>
      <select
        className="bg-white appearance-none border border-none  py-2 px-3 text-sm focus:outline-none focus:border-red-800 focus:shadow-xl"
        style={{
          WebkitAppearance: 'none',
          MozAppearance: 'none',


          boxShadow: props.focusState.countrylist
            ? "rgba(76, 151, 203, 0.733) 0px 0px 0px 1px, rgba(0, 0, 0, 0.07) 0px 1.10205px 1.30615px 0px, rgba(49, 148, 207, 0.275) 0px 0.10205px 0.153074px 3.5918px"
            : props.errors.address
            ? "0 0 0 0.5px rgb(220, 39, 39)"
            : "0 0 0 1px rgba(175,175,175,0.3), 0 1px 4px 0px rgba(0,0,0,0.1)",
          fontSize: "14px",
          borderRadius: "6px 6px  0 0 ",
          height: "36px",
          zIndex: props.focusState.countrylist ? 10 : 1,
          fontFamily: "segoeui-semibold",
        }}
        onFocus={() => props.handleFocus("countrylist")}
        onBlur={() => props.handleBlur("countrylist")}
        value={props.selectedCountry}
        onChange={props.handleCountryChange}
      >
        <option value=""></option>
        {countries.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </select>
      <input
        type="input"
        placeholder="Address"
        {...props.register("address", { required: "Required" })}
        className="border rounded border-none py-2 px-3 text-sm focus:outline-none focus:border-red-800 focus:shadow-xl"
        style={{

          width:'353px',
          height: "20px",
          boxShadow: props.focusState.address
            ? "rgba(76, 151, 203, 0.733) 0px 0px 0px 1px, rgba(0, 0, 0, 0.07) 0px 1.10205px 1.30615px 0px, rgba(49, 148, 207, 0.275) 0px 0.10205px 0.153074px 3.5918px"
            : props.errors.address
            ? "0 0 0 0.5px rgb(220, 39, 39)"
            : "0 0 0 1px rgba(175,175,175,0.3), 0 1px 4px 0px rgba(0,0,0,0.1)",
          fontSize: "14px",
          borderRadius: "0 0 6px 6px",
          
          zIndex: props.focusState.address ? 10 : 1,
          fontFamily: "segoeui-semibold",
        }}
        onFocus={() => props.handleFocus("address")}
        onBlur={() => props.handleBlur("address")}
      ></input>
    </div>
  );
};
export default BillingAddressComtainer;
