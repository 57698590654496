import useGetUserInfo from "hooks/useUserInfo.js";
import useGetVoiceData from "./getvoicedata.js";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

export default function useRemainingVoiceSlots() {
    const { accountTier } = useGetUserInfo();
    const [TotalSlots, setTotalSlots] = useState('0');
    const [UsedSlots, setUsedSlots] = useState('0');

    const slotTable =
    {
        "Free": 0,
        "Tier 1": 0,
        "Tier 2": 2,
        "Tier 3": 3,
    }
    const { VoiceArray, refetch } = useGetVoiceData();

    const fetchData = () => {
        refetch().then(() => {
            try {
                let voices = [];
                //const balls = JSON.parse(VoiceArray)
                if (VoiceArray) {
                    voices = JSON.parse(VoiceArray)
                    setUsedSlots(Number(voices.length))
                }

                if (accountTier !== 'Not Subscribed to a Plan!' && accountTier !== "") {
                    setTotalSlots(Number(slotTable[accountTier]))
                }
            } catch (e) {
                setTotalSlots('0')
            }
        })
    }

    function canMakeNewVoice() {

        if (UsedSlots !== '0' && TotalSlots !== "0") {
            if (UsedSlots >= TotalSlots) {
                console.log('case 1')
                return false
            } else {
                return true
            }
        } else {
            if (accountTier === "Not Subscribed to a Plan!") {
                return false
            }
            return 'waiting'


        }
    }

    return { UsedSlots, TotalSlots, setUsedSlots, canMakeNewVoice, fetchData }
}