import React, { useState } from "react";
import "css/voicelab.css"
import placeholder from "images/logoObject.png"

export default function VoiceLabTemplate(props) {
  const record_id = props.record_id;
  const name = props.name;
  let image = props.image;
  if (!image) {
    image = placeholder
  }
  const [showRemoveComponent, setShowRemoveComponent] = useState(false);
  const [voiceInfoVisible, setVoiceInfoVisible] = useState(true);

  const HandleRemoveClick = () => {
    props.HandleRemoveClick([name, record_id]);

    setShowRemoveComponent(true);
  }
  const HandleUseClick = () => {
    props.HandleUseClick(image, name, record_id);
  }

  return (
    <div className="voicelab-slot">
      <div className="voicelab-slotTop">
        <img src={image} className="voicelab-slotIMG"></img>
        <label>{name}</label>
      </div>
      <div className="voicelab-slotBottom">
        <label className="slotBottomButtonLeft" onClick={HandleUseClick} >use</label>
        <label className="slotBottomButtonRight" onClick={HandleRemoveClick}>remove</label>
      </div>
    </div>
  )
}
