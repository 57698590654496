// hooks/UseTextToSpeech.js
import { useRef } from "react";
import { useState } from "react";
import decreaseCharCount from "./AccountHandling/CharDecrement.js";
import useGetCharCount from "./useGetCharCount.js";
const UseTextToSpeech = (stability, similarity) => {
  stability = stability * 0.01;
  similarity = similarity * 0.01;
  const newStability = stability.toFixed(2);
  const newSimilarity = similarity.toFixed(2);
  const [blobURL, setBlobURL] = useState(null);
  const audioPlayerRef = useRef();

  const getVoiceId = (voicePreset) => {
    // function that takes in the name key attached to the voice id and returns the id
    return 0;
  };

  const downloadAudio = () => {
    const link = document.createElement("a");
    link.download = "celeb-voice-audio-download.mp3";
    link.href = blobURL;
    link.click();
    //URL.revokeObjectURL(blobURL);
    console.log("Audio Downloaded");
  };

  const handleTextToSpeech = async (textin, voiceId, apiKey) => {
    const url = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`; //url to api call
    const headers = {
      "Content-Type": "application/json",
      "xi-api-key": apiKey,
    };

    const data = {
      text: textin,
      voice_settings: {
        stability: Number(newStability), // this number is a percentage so must be less than 1
        similarity_boost: Number(newSimilarity),
      },
    };

    const response = await fetch(url, {
      // response is the return data of the fetch
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });

    if (response.ok) {
      // if the response is okay
      //const audi = await response.text();
      const audioBlob = await response.blob(); // raw audio data

      const blob = new Blob([audioBlob], { type: "audio/mp3" });
      const audioUrl = URL.createObjectURL(blob);
      audioPlayerRef.current.src = audioUrl; // audio ref object holds the data from the audio blob

      //setaudioPlayerRef2(audioPlayerRef)
      setBlobURL(audioUrl);
    } else {
      console.log("something went wrong")
      return false
    }
  };

  return {
    handleTextToSpeech,
    audioPlayerRef,
    getVoiceId,
    downloadAudio,
    blobURL
  };
};

export default UseTextToSpeech;
