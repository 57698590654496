import Stripe from "stripe";
import pb from "UIM";
export default async function cancelStripeSubscription() {
  try {
    const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY);
    const records = await pb.collection("accountTiers").getList(1, 50, {
      filter: `userID="${pb.authStore.model.id}"`,
    });

    const record = records.items[0];
    const subID = record["subID"];
    const deleted = await stripe.subscriptions.cancel(subID);
  } catch (e) {
    console.log("failed to cancel subscription");
  }
}
