//PresetsOption.js
import React, { useState } from "react";
import "../../css/presetOptions.css";
import "../../css/main.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

import "swiper/css";

//IMG IMPORTS
import donald from "../../images/donald.webp";
import biden from "../../images/biden.webp";
import obama from "../../images/obama.webp";
import benShapiro from "../../images/benShapiro.webp";
import ye from "../../images/ye.webp";
import andrewTate from "../../images/andrewTate.webp";
import elonMusk from "../../images/elonMusk.webp";
import joeRogan from "../../images/joeRogan.webp";
import queen from "../../images/queen.webp";
import johnnyDepp from "../../images/johnnyDepp.webp";
import neilDegrasse from "../../images/neilDegrasse.webp";
import billGates from "../../images/billGates.webp";
import iceSpice from "../../images/iceSpice.webp";
import morganFreeman from "../../images/morganFreeman.webp";
import steveJobs from "../../images/steveJobs.webp";
import aoc from "../../images/aoc.webp";
import adinRoss from "../../images/adinRoss.webp";
import hasan from "../../images/hasan.webp";
import xqc from "../../images/xqc.webp";
import davidGoggins from "../../images/davidGoggins.webp";
import lock from "../../images/lock.webp";

//Code

export default function PresetOptions({ handleSelect }) {
  const [activeOption, setActiveOption] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState({ name: "", img: "" });

  const handleClick = (option, presetImg, color) => {
    handleSelect(option, presetImg, color);
    setActiveOption(option);
  };

  return (
    <Swiper className="swiper-container" spaceBetween={0} slidesPerView={3.4}>





















      {/*active-voices*/}
      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("davidGoggins", davidGoggins, "#5360be")}
      >
        <div className="PresetOptions">
          <img
            src={davidGoggins}
            alt="Avatar"
            className={`PresetOptions-img ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">David Goggins</div>
        </div>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("elonMusk", elonMusk, "#5360be")}
      >
        <div className="PresetOptions">
          <img
            src={elonMusk}
            alt="Avatar"
            className={`PresetOptions-img ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Elon Musk</div>
        </div>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("ye", ye, "#5360be")}
      >
        <div className="PresetOptions">
          <img
            src={ye}
            alt="Avatar"
            className={`PresetOptions-img ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Ye</div>
        </div>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("andrewTate", andrewTate, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={andrewTate}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Andrew Tate</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("iceSpice", iceSpice, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={iceSpice}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Ice Spice</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("billGates", billGates, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={billGates}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Bill Gates</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("elonMusk", elonMusk, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={elonMusk}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Elon Musk</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("morganFreeman", morganFreeman, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={morganFreeman}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Morgan Freeman</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("steveJobs", steveJobs, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={steveJobs}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Steve Jobs</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("ye", ye, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={ye}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Ye</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("aoc", aoc, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={aoc}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">AOC</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("neilDegrasse", neilDegrasse, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={neilDegrasse}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Neil Degrasse</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("hasan", hasan, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={hasan}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Hasan</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("adinRoss", adinRoss, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={adinRoss}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Adin Ross</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("benShapiro", benShapiro, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={benShapiro}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Ben Shapiro</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("xqc", xqc, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={xqc}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">XQC</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("queen", queen, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={queen}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Queen Elizabeth</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("joeRogan", joeRogan, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={joeRogan}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Joe Rogan</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("davidGoggins", davidGoggins, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={davidGoggins}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">David Goggins</div>
        </Link>
      </SwiperSlide>

      <SwiperSlide
        className="swiper-slide"
        onClick={() => handleClick("johnnyDepp", johnnyDepp, "#d82129")}
      >
        <Link className="PresetOptions" to="/pricing">
          <img
            src={johnnyDepp}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <img src={lock} alt="Lock" className="PresetOptions-imgLock" />
          <div className="PresetOptions-text">Johnny Depp</div>
        </Link>
      </SwiperSlide>


      {/*end-of-voices*/}





















    </Swiper>
  );
}

