import React, { useState, useEffect } from "react";
import "css/voicelab.css"
import Xmark from "images/xmark.webp";
import logoObject from "images/logoObject.png";
import { useForm } from "react-hook-form";
import { Oval } from "react-loader-spinner";
import pb from "UIM";
import useRemainingVoiceSlots from "./availableVoices.js";
import axios from 'axios';

export default function VoiceInfo({ updateState, InfoClose, className, refetchFunction, onLoadingChange }) {
  const [containerVisible, setContainerVisible] = useState(false);
  const [pageToggle, setPageToggle] = useState(true);
  const [nameInput, setNameInput] = useState("");
  const [audio1Input, setAudio1Input] = useState();
  const [audio2Input, setAudio2Input] = useState();
  const [audio3Input, setAudio3Input] = useState();
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState(logoObject);
  const [Loading, SetLoading] = useState(false);


  const [RefetchComplete, setRefetchComplete] = useState(false);
  const [ErrorCreatingVoice, setErrorCreatingVoice] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLoadingChange = () => {
    onLoadingChange(Loading);
  }

  useEffect(() => {
    handleLoadingChange();
  }, [Loading]);


  useEffect(() => {

  }, [RefetchComplete])

  useEffect(() => {
    if (!file) {
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setPreviewUrl(objectUrl);
    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [file]);


  const handleClose = () => {
    setContainerVisible(false);
    InfoClose();
    setPageToggle(true);
    setNameInput("");
  };

  const handleNext = () => {
    const byte_limit = 6815744;

    const CheckFileSize = (audio_files) => {
      for (let i = 0; i < audio_files.length; i++) {
        let audio = audio_files[i];
        if (audio) {
          if (audio.size > byte_limit) {
            return false
          }
        }
      }
      return true
    }

    if (nameInput) {
      if (nameInput.length > 30) {
        alert('Voice name is too long. The maximum is 30 characters');
        return;
      }
      if (audio1Input || audio2Input || audio3Input) {
        if (CheckFileSize([audio1Input, audio2Input, audio3Input])) {
          setPageToggle(false);
          setNameInput("");
        } else {
          alert("Your audio files are too large")
          return
        }
      } else {
        alert('Please provide at least one audio file');
        return
      }
    } else {
      alert('Please add a name for your voice!');
      return
    }


  };

  const VoiceInfoDataSubmit = async (VoiceInfoData) => {
    setRefetchComplete(false)
    setPageToggle(true);
    SetLoading(true)
    setErrorCreatingVoice(false)// reset the error use state
    setNameInput("");
    try {
      let audio1;
      let audio2;
      let audio3;
      let picture;
      let VoiceName;

      if (VoiceInfoData['audio1'].length > 0) audio1 = VoiceInfoData['audio1'][0]
      if (VoiceInfoData['audio2'].length > 0) audio2 = VoiceInfoData['audio2'][0]
      if (VoiceInfoData['audio3'].length > 0) audio3 = VoiceInfoData['audio3'][0]
      if (VoiceInfoData['image'].length > 0) picture = VoiceInfoData['image'][0]
      VoiceName = VoiceInfoData['name']

      const formData = new FormData();

      if (audio1) formData.append('audio_files', audio1)
      if (audio2) formData.append('audio_files', audio2)
      if (audio3) formData.append('audio_files', audio3)
      if (picture) formData.append('image', picture);

      formData.append('voice_name', VoiceName);
      formData.append('userID', pb.authStore.model.id)

      try {
        const createdRecord = await pb.collection('voice_files').create(formData).then((res) => {
          handleClose();
          setRefetchComplete(true)
          refetchFunction();
          updateState()
        })
      } catch (e) {
        SetLoading(false)
        setErrorCreatingVoice(true)
        alert("There was an error creating your voice. Please try again later")
      }

    } catch (e) {
      console.log(e)
      SetLoading(false)
      setErrorCreatingVoice(true)
      alert("There was an error creating your voice. Please try again later")
    }
    SetLoading(false)
  }

  return (
    <div className={`voicelab-VoiceInfoWrapper ${className}`}>
      {!Loading ? (
        <>
          {pageToggle ? (
            <div className="voicelab-VoiceInfo">
              <form className="VoiceInfo-Form">

                <div>
                  <div className="VoiceInfo-titleContainer">
                    <label className="VoiceInfo-title">Add Voice</label>
                    <img
                      src={Xmark} className="VoiceInfo-X"
                      onClick={handleClose}
                    />
                  </div>
                  <div className="VoiceInfo-NameContainer">
                    <label className="VoiceInfo-NameLabel">Name -</label>
                    <input
                      className="VoiceInfo-NameInput"
                      placeholder="Name"
                      {...register("name")}
                      value={nameInput}
                      onChange={(event) => {
                        setNameInput(event.target.value);
                      }}

                    />

                  </div>
                  <input
                    className="VoiceInfo-AudioFile"
                    type="file"
                    accept="audio/*"
                    {...register("audio1")}
                    onChange={(event) => setAudio1Input(event.target.files[0])}
                  />
                  <input
                    className="VoiceInfo-AudioFile"
                    type="file"
                    accept="audio/*"
                    {...register("audio2")}
                    onChange={(event) => setAudio2Input(event.target.files[0])}
                  />
                  <input
                    className="VoiceInfo-AudioFile"
                    type="file"
                    accept="audio/*"
                    {...register("audio3")}
                    onChange={(event) => setAudio3Input(event.target.files[0])}
                  />
                </div>
                <div className="voicelab-InfoButtons">
                  <label className="VoiceInfo-ButtonLeft" onClick={handleClose}>Cancel</label>
                  <label
                    className="VoiceInfo-ButtonRight"
                    //disabled={!nameInput || !audio1Input || !audio2Input || !audio3Input}
                    onClick={handleNext}
                  >
                    Next
                  </label>
                </div>
              </form>

            </div>
          ) : (
            <div className="voicelab-VoiceInfo">
              <form onSubmit={handleSubmit(VoiceInfoDataSubmit)} className="VoiceInfo-Form">

                <div>
                  <div className="VoiceInfo-titleContainer">

                    <label className="VoiceInfo-title">Add Voice</label>
                    <img
                      src={Xmark} className="VoiceInfo-X"
                      onClick={handleClose}
                    />
                  </div>
                </div>
                <div className="VoiceInfo-ImageContainer">
                  <label className="VoiceInfo-ImageLabel">Choose an Avatar for your voice</label>
                  {previewUrl && <img src={previewUrl} alt="Preview" className="voicelab-previewIMG" />}

                  <input type="file" accept=".mp3,.wav" className="voicelab-removeNone" />
                  <input type="file" accept=".mp3,.wav" className="voicelab-removeNone" />
                  <input type="file" accept=".mp3,.wav" className="voicelab-removeNone" />
                  {ErrorCreatingVoice ? (
                    <label className="voicelab-ErrorText">Error creating voice</label>
                  ) : null}
                  <div className="VoiceInfo-ImageFileContainer">
                    <input
                      className="VoiceInfo-ImageFile"
                      type="file"
                      accept="image/*"
                      {...register("image")}
                      onChange={(event) => {
                        setFile(event.target.files[0]);
                        register('image').onChange(event); // call the onChange from register
                      }}
                    />

                  </div>

                </div>
                <div className="voicelab-InfoButtons">
                  <label className="VoiceInfo-ButtonLeft" onClick={handleClose}>Cancel</label>
                  <button className="VoiceInfo-ButtonRight" type="submit">Create</button>
                </div>
              </form>

            </div>)}
        </>) : <div>
        <Oval
          height={140}
          width={140}
          color="#8250ff"
          wrapperStyle={{}}
          ariaLabel="oval-loading"
          secondaryColor="#380ca4"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>}
    </div>
  );
}



