import React, { useState, useEffect } from "react";
import "../css/home.css";
import "../css/password.css";
import "../css/signupin.css";
import { set, useForm } from "react-hook-form";
import LogoObject from "images/logoObject.png";
import { Link } from "react-router-dom";
import pb from "UIM";
import DeleteAreYouSure from "hooks/AccountHandling/DeleteAreYouSure";

const Delete = () => {
  return (
    <div>
      <DeleteAreYouSure />
    </div>
  );
};

export default Delete;
