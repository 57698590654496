import pb from "UIM";
import { useEffect, useState } from "react";

export default function useVerified() {
  const [isVerified, setIsVerified] = useState("loading");

  useEffect(() => {
    async function checkVerified() {
      try {
        const id = pb.authStore.model.id;
        const userdata = await pb.collection("users").getOne(id);
        
        setIsVerified(userdata.verified);
      } catch (e) {
        console.log(e);
      }
    }
    if (pb.authStore.isValid) checkVerified(); // only run this function if user is logged in
  }, []);

  return { isVerified };
}
