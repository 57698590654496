// Pricing.jsx
import React, { useState, useEffect } from "react";
import "../css/main.css";
import "../css/pricing.css";
import Topbar from "hooks/Topbar.js";
import TopbarLI from "hooks/TopbarLI.js";
import { Link } from "react-router-dom";
import createPaymentLink from "hooks/AccountHandling/CreatePaymentLink";
import pb from "UIM";
import PricingContainer from "hooks/PricingContainer.js";
import Footer from "hooks/Footer";
import normcheckmark from "images/checkmark.webp";
import yellowcheckmark from "images/golden_checkmark.webp";
import Switch from "react-switch";
import useLimitPayment from "hooks/useLimitPayment";
import CompareAccountTier from "js/CheckAccountTier";

export default function Home() {
  const pricingData = [
    {
      title: "Free",
      subtitle: "Tier 0",
      description:
        "Dive into Celeb-Voice with 3 preset rotations, perfect for creators eager to experiment and explore. Aswell as access to our Celeb-Mix Editor.",
      price: "$0",
      button: "Sign Up",
      features: [
        { label: "250 Characters per Month", available: true },
        { label: "3 Voice presets rotated Weekly", available: true },
        { label: "Celeb-Mix AI Voice Editor Creator Tool", available: true },
        //{ label: "Ad Free", available: false },
      ],
      link: 0,
      checkmark: normcheckmark,
    },

    {
      title: "Pro",
      titleClass: "titleClass-proTier",
      subtitle: "Tier 2 - Most Popular",
      subtitleClass: "subtitleClass-proTier",
      PriceClass: "PriceClass-proTier",
      description:
        "Engage in content creation with 30,000 characters, access to the dynamic Celeb-Mix Editor for interactive conversations, and the ability to create your own voices with the brand new Voice Lab!",
      price: "$9.99",
      buttonClass: "buttonClass-proTier",
      button: "Subscribe",
      features: [
        { label: "30,000 Characters per Month", available: true },
        { label: "Access to all Voice AI presets", available: true },
        { label: "Celeb-Mix AI Voice Editor Creator Tool", available: true },
        { label: "Create your own voices with Voice Lab", available: true },
        //{ label: "Ad Free", available: false },
      ],
      link: 2,
      external: true,
      checkmark: yellowcheckmark,
    },
    {
      title: "Starter",
      subtitle: "Tier 1",
      description:
        "Unlock 10,000 characters, all presets, and access to all free tier features, providing ample creative freedom at an affordable price.",
      price: "$2.99",
      button: "Subscribe",
      features: [
        { label: "10,000 Characters per Month", available: true },
        { label: "Access to all Voice AI presets", available: true },
        { label: "Celeb-Mix AI Voice Editor Creator Tool", available: true },
        //{ label: "Ad Free", available: false },
      ],
      link: 1,
      external: true,
      checkmark: normcheckmark,
    },
    {
      title: "Ultimate",
      titleClass: "titleClass-proTier",
      subtitle: "Tier 3 - Best Value",
      subtitleClass: "subtitleClass-proTier",
      PriceClass: "PriceClass-proTier",
      description:
        "Elevate your creations with 100K characters, access to the revolutionary Celeb-Mix Editor, pushing the boundaries of storytelling and engagement, and the ability to create your own voices with the brand new Voice Lab!",
      price: "$45.00",
      buttonClass: "buttonClass-proTier",
      button: "Subscribe",
      features: [
        { label: "100,000 Characters per Month", available: true },
        { label: "Access to all Voice AI presets", available: true },
        { label: "Celeb-Mix AI Voice Editor Creator Tool", available: true },
        { label: "Create your own voices with Voice Lab", available: true },
        //{ label: "Ad Free", available: false },
      ],
      link: 3,
      external: true,
      checkmark: yellowcheckmark,
    },
  ];
  const pricingDataYearly = [
    {
      title: "Free",
      subtitle: "Tier 0",
      description:
        "Dive into Celeb-Voice with 3 preset rotations, perfect for creators eager to experiment and explore. Aswell as access to our Celeb-Mix Editor.",
      price: "$0",
      button: "Sign Up",
      features: [
        { label: "250 Characters per Month", available: true },
        { label: "3 Voice presets rotated Weekly", available: true },
        { label: "Celeb-Mix AI Voice Editor Creator Tool", available: true },
        //{ label: "Ad Free", available: false },
      ],
      link: 0,
      checkmark: normcheckmark,
    },

    {
      title: "Pro",
      titleClass: "titleClass-proTier",
      subtitle: "Tier 2 - Most Popular",
      subtitleClass: "subtitleClass-proTier",
      PriceClass: "PriceClass-proTier",
      description:
        "Engage in content creation with 30,000 characters, access to the dynamic Celeb-Mix Editor for interactive conversations, and the ability to create your own voices with the brand new Voice Lab!",
      price: "$9.00",
      buttonClass: "buttonClass-proTier",
      button: "Subscribe",
      features: [
        { label: "30,000 Characters per Month", available: true },
        { label: "Access to all Voice AI presets", available: true },
        { label: "Celeb-Mix AI Voice Editor Creator Tool", available: true },
        { label: "Create your own voices with Voice Lab", available: true },
      ],
      link: 20,
      external: true,
      checkmark: yellowcheckmark,
    },
    {
      title: "Starter",
      subtitle: "Tier 1",
      description:
        "Unlock 10,000 characters, all presets, and access to all free tier features, providing ample creative freedom at an affordable price.",
      price: "$2.75",
      button: "Subscribe",
      features: [
        { label: "10,000 Characters per Month", available: true },
        { label: "Access to all Voice AI presets", available: true },
        { label: "Celeb-Mix AI Voice Editor Creator Tool", available: true },
        //{ label: "Ad Free", available: false },
      ],
      link: 10,
      external: true,
      checkmark: normcheckmark,
    },
    {
      title: "Ultimate",
      titleClass: "titleClass-proTier",
      subtitle: "Tier 3 - Best Value",
      subtitleClass: "subtitleClass-proTier",
      PriceClass: "PriceClass-proTier",
      description:
        "Elevate your creations with 100K characters, access to the revolutionary Celeb-Mix Editor, pushing the boundaries of storytelling and engagement, and the ability to create your own voices with the brand new Voice Lab!",
      price: "$40.00",
      buttonClass: "buttonClass-proTier",
      button: "Subscribe",
      features: [
        { label: "100,000 Characters per Month", available: true },
        { label: "Access to all Voice AI presets", available: true },
        { label: "Celeb-Mix AI Voice Editor Creator Tool", available: true },
        { label: "Create your own voices with Voice Lab", available: true },
        //{ label: "Ad Free", available: false },
      ],
      link: 30,
      external: true,
      checkmark: yellowcheckmark,
    },
  ];
  const TopbarComponent = ({ isValid }) => {
    return isValid ? <TopbarLI /> : <Topbar />;
  };
  const [displayedData, setDisplayedData] = useState(pricingData);
  const [isShowingMonthly, setIsShowingMonthly] = useState(false);

  const toggleData = () => {
    setIsShowingMonthly(!isShowingMonthly);
  };

  useEffect(() => {
    if (isShowingMonthly) {
      setDisplayedData(pricingDataYearly);
    } else {
      setDisplayedData(pricingData);
    }
  }, [isShowingMonthly]);

  return (
    <div className="mainWrapper">
      <TopbarComponent isValid={pb.authStore.isValid} />
      <div className="pricing-mainContent">
        <div className="pricing-SwitchContainer">
          <label className="pricing-SwitchLabel" style={{ textAlign: "end" }}>
            Monthly
          </label>
          <Switch
            checked={isShowingMonthly}
            onChange={toggleData}
            onColor="#380ca4"
            onHandleColor="#8250ff"
            uncheckedIcon={false}
            checkedIcon={false}
          />
          <label className="pricing-SwitchLabelYearly">
            Yearly
            <label className="pricing-SwitchLabelDeal"> (1 month free)</label>
          </label>
        </div>
        <div className="pricing-row">
          {displayedData.map((pricing, index) => {
            const compare = CompareAccountTier(pricing.link);
            let buttonClass = pricing.buttonClass;
            let buttonText = pricing.button;

            if (pricing.button === "Subscribe") {
              if (compare === false) {
                buttonClass = "buttonClass-grey";
                buttonText = "Cannot downgrade tier";
              }
            }

            return (
              <PricingContainer
                key={index}
                title={pricing.title}
                titleClass={pricing.titleClass}
                subtitle={pricing.subtitle}
                subtitleClass={pricing.subtitleClass}
                description={pricing.description}
                price={pricing.price}
                buttonClass={buttonClass}
                button={buttonText}
                features={pricing.features}
                link={pricing.link}
                PriceClass={pricing.PriceClass}
                checkmark={pricing.checkmark}
              />
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
