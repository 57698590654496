import React, { useState, useEffect, useRef } from "react";
import "../css/home.css";
import "../css/signupin.css";
import "../css/user.css";
import "../css/footer.css";
import LogoObject from "images/logoObject.png";
import { Link, useNavigate } from "react-router-dom";
import pb from "UIM";
import useVerified from "hooks/useVerified";
import Footer from "hooks/Footer";
import useGetUserInfo from "hooks/useUserInfo";
import useGetCharCount from "hooks/useGetCharCount";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import Xmark from "images/xmark.webp";

export default function User() {
  const { totalCharCount, characterResetTime, accountTier } = useGetUserInfo();
  const { charCount } = useGetCharCount();
  const charCountWithComma = charCount.toLocaleString();
  const { isVerified } = useVerified();
  const [dummy, setDummy] = useState(0);
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const navigator = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();

  const checkboxRef1 = React.useRef(null);
  const checkboxRef2 = React.useRef(null);
  const checkboxRef3 = React.useRef(null);
  const checkboxRef4 = React.useRef(null);
  const checkboxRef5 = register("Q5");
  const checkboxValue5 = watch("Q5", false);

  function LogOut() {
    pb.authStore.clear();
    //setDummy(Math.random);
    navigator("/");
  }
  const deleteButton = () => {
    //setDummy(Math.random);
    navigator("/delete");
  };
  const CancelButton = () => {
    //setDummy(Math.random);
    navigator("/cancelSub");
  };

  useEffect(() => {
    if (!pb.authStore.isValid) {
      navigator("/signin");
    }
  }, [accountTier]);
  const SubmitCancelFormData = async (CancelelFormData) => {
    console.log(CancelelFormData);
    CancelButton();
  };
  const CancelForm = ({ setShowCancelForm }) => {
    const handleCancelFormClose = () => {
      setShowCancelForm(false);
    };
    return (
      <div className={`user-CancelForm`}>
        <div className={`CancelForm-wrapper`}>
          <div className="CancelForm-h1Div">
            <label className="CancelForm-h1">Cancelling Celeb-Voice?</label>
          </div>
          <div className="CancelForm-h2Div">
            <label className="CancelForm-h2">Please let us know why</label>
          </div>
          <form
            className="CancelForm-Form"
            onSubmit={handleSubmit(SubmitCancelFormData)}
          >
            <div
              className="CancelForm-div"
              onClick={(e) => {
                if (e.target !== checkboxRef1.current) {
                  checkboxRef1.current.click();
                  setValue("Q1", checkboxRef1.current.checked);
                }
              }}
            >
              <input
                type="checkbox"
                className="CancelForm-input"
                {...register("Q1")}
                ref={checkboxRef1}
              />
              <label className="CancelForm-inputLabel">
                Does not have features that I need
              </label>
            </div>
            <div
              className="CancelForm-div"
              onClick={(e) => {
                if (e.target !== checkboxRef2.current) {
                  checkboxRef2.current.click();
                  setValue("Q2", checkboxRef2.current.checked);
                }
              }}
            >
              <input
                type="checkbox"
                {...register("Q2")}
                className="CancelForm-input"
                ref={checkboxRef2}
              />
              <label className="CancelForm-inputLabel">Too expensive</label>
            </div>
            <div
              className="CancelForm-div"
              onClick={(e) => {
                if (e.target !== checkboxRef3.current) {
                  checkboxRef3.current.click();
                  setValue("Q3", checkboxRef3.current.checked);
                }
              }}
            >
              <input
                type="checkbox"
                {...register("Q3")}
                className="CancelForm-input"
                ref={checkboxRef3}
              />
              <label className="CancelForm-inputLabel">A lot of Bugs</label>
            </div>
            <div
              className="CancelForm-div"
              onClick={(e) => {
                if (e.target !== checkboxRef4.current) {
                  checkboxRef4.current.click();
                  setValue("Q4", checkboxRef4.current.checked);
                }
              }}
            >
              <input
                type="checkbox"
                {...register("Q4")}
                className="CancelForm-input"
                ref={checkboxRef4}
              />
              <label className="CancelForm-inputLabel">
                Do not need it anymore
              </label>
            </div>

            <div
              className="CancelForm-div"
              onClick={(e) => {
                const newCheckedState = !checkboxValue5;
                setValue("Q5", newCheckedState);
                if (newCheckedState) {
                  setShowOther(true);
                  console.log(newCheckedState);
                } else {
                  setShowOther(false);
                  console.log(newCheckedState);
                }
              }}
            >
              <input
                type="checkbox"
                className="CancelForm-inputLabel"
                ref={checkboxRef5}
                checked={checkboxValue5}
              />
              <label className="CancelForm-inputLabel">Other</label>
            </div>
            {showOther ? (
              <div className="CancelForm-textAreaContainer">
                <textarea className="CancelForm-textArea" />
              </div>
            ) : null}

            <button className="CancelForm-Submit" type="submit">
              Cancel Subscription
            </button>
            <img
              onClick={handleCancelFormClose}
              className="CancelForm-CloseButton"
              src={Xmark}
            />
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="user-mainWrapper">
      {showCancelForm ? (
        <CancelForm setShowCancelForm={setShowCancelForm} />
      ) : null}
      <Link to="/" className="user-logoContainer">
        <img src={LogoObject} className="user-logo"></img>
      </Link>
      <div className="user-mainContent">
        <form className="user-form">
          <div className="user-row">
            <label className="user-label">Email:</label>
            <label className="user-labelJS">
              {pb.authStore.isValid ? pb.authStore.model.email : null}
            </label>
          </div>
          <div className="user-row">
            <label className="user-label">Character Use:</label>
            <label className="user-labelJS">
              {charCountWithComma} /{totalCharCount}
            </label>
          </div>
          <div className="user-row">
            <label className="user-label">Character Reset:</label>
            <label className="user-labelJS">{characterResetTime}</label>
          </div>
          <div className="user-row">
            <label className="user-label">Account Tier:</label>
            <label className="user-labelJS">{accountTier}</label>
          </div>
          <div className="user-rowPass">
            <Link to="/resetpassword" className="signForm-linkSign">
              Reset Password
            </Link>
          </div>
          <label className="user-logButton" type="" onClick={LogOut}>
            Log Out
          </label>

          {accountTier === "Free" ? null : (
            <label
              className="user-delButton"
              type=""
              onClick={() => setShowCancelForm(true)}
            >
              Cancel Subscription
            </label>
          )}

          <label className="user-delButton" type="" onClick={deleteButton}>
            Delete Account
          </label>
        </form>
      </div>
      <Footer />
    </div>
  );
}
