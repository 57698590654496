import Stripe from "stripe";
const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY)
async function createPaymentLink(pocketBaseId, priceAPI) {
  await stripe.paymentLinks.create({
    line_items: [
      {
        "price": priceAPI,
        "quantity": 1,
      }
    ],
    metadata: {
      "pocketbaseId": `${pocketBaseId}`,
    },
    allow_promotion_codes: true,
    phone_number_collection: {
      enabled: false,
    },
    billing_address_collection: 'required',

    after_completion: {
      type: 'redirect',
      redirect: {
        url: 'https://celeb-voice.com/SuccesfulPurchase',
      }
    },
  }).then((res) => {
    window.location.href = res.url

  })
}

export default createPaymentLink