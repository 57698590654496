//Home.jsx
import React, { useEffect, useState } from "react";
import "../css/home.css";
import "../css/main.css";
import Topbar from "hooks/Topbar.js";
import TopbarLI from "hooks/TopbarLI.js";
import UseTextToSpeech from "hooks/UseTextToSpeech.js";
import AudioPlayer from "hooks/AudioPlayer";
import placeholderImg from "../images/logoObject.png";
import { useForm } from "react-hook-form";
import pb from "UIM";
import Footer from "hooks/Footer";
import { Link } from "react-router-dom";
import LockPresetOptions from "hooks/PresetMenus/LockPresentOption";
import PresetOptions from "hooks/PresetMenus/PresetOption.js";
import PresetMenu from "hooks/PresetMenus/PresetMenu.js";
import LockPresetMenu from "hooks/PresetMenus/LockPresentMenu.js";
import download from "images/download.webp";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "../css/sliderStyle.css";
import useGetCharCount from "hooks/useGetCharCount";
import useGetUserInfo from "hooks/useUserInfo";
import decreaseCharCount from "hooks/AccountHandling/CharDecrement";
import { Oval } from "react-loader-spinner";
import useVerified from "hooks/useVerified";
import bakdrop from "images/bakdrop.webp";
import { newGetVoiceID } from "js/newGetVoiceID";
import ClickedOnCelebMix from "js/celeb-mix-analytics";

async function canProcceed(charAmount) {
  try {
    const records = await pb.collection("charCounts").getList(1, 2, {
      filter: `userID="${pb.authStore.model.id}"`,
    });
    const record = records.items[0];
    const charCount = record["charCount"];

    if (charCount - charAmount < 0) {
      return false;
    }
    return true;
  } catch (e) {
    console.log(
      "Possible that user has no 'charCount' record, they must select a plan"
    );
    return false;
  }
}

export default function Home() {
  //LogOut();
  //pb.authStore.clear();
  ///////////////////////
  const [dummy, setDummy] = useState(0);
  const { charCount } = useGetCharCount();
  const [firstPageLoad, setFirstPageLoaded] = useState(true);
  const [liveCharCount, setLiveCharCount] = useState(0);
  if (charCount != 0 && firstPageLoad) {
    setLiveCharCount(charCount);
    setFirstPageLoaded(false);
  }
  const { accountTier } = useGetUserInfo();
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(true);
  const [outOfCharacters, setOutOfCharacters] = useState(false);
  const [hasSelctedPreset, setHasSelectedPreset] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState("");
  const [activeColor, setActiveColor] = useState("");
  const [stabilitySliderValue, setStabilitySliderValue] = useState(80);
  const [similaritySliderValue, setSimilaritySliderValue] = useState(80);
  const [menuVisible, setMenuVisible] = useState(false);
  const [isAnimationStopped, setIsAnimationStopped] = useState(false);
  const [voiceGenerationError, setVoiceGenerationError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedVoiceError, setSelectedVoiceError] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [generateGray, setGenerateGray] = useState(true);

  const handlePresetSelect = (option, presetImg, color) => {
    setSelectedPreset({ name: option, img: presetImg });
    console.log(option);
    setActiveColor(color);
    setGenerateGray(false);
  };
  function LogOut() {
    // should be removed and moved to home page, this is just for testing
    pb.authStore.clear();
    setDummy(Math.random);
  }

  const { handleTextToSpeech, audioPlayerRef, blobURL, downloadAudio } =
    UseTextToSpeech(stabilitySliderValue, similaritySliderValue);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const downloadButtonSubmit = () => {
    downloadAudio();
    setDownloadButtonDisabled(true);
  };
  const VoiceAppDataSubmit = (VoiceAppData) => {
    setSelectedVoiceError(false);
    setVoiceGenerationError(false);
    if (!pb.authStore.isValid) {
      window.location.href = "/signUp";
    }

    setOutOfCharacters(false);

    if (selectedPreset != "") {
      setLoading(true);
      const voice_ID_and_api_key = newGetVoiceID(selectedPreset["name"]);
      const voice_ID = voice_ID_and_api_key[0];
      const apiKey = voice_ID_and_api_key[1];
      const charAmount = VoiceAppData.textInput.length;

      const proc = canProcceed(charAmount).then((res) => {
        if (res) {
          try {
            handleTextToSpeech(VoiceAppData.textInput, voice_ID, apiKey)
              .then((res) => {
                setLoading(false);
                if (res === false) {
                  //error has occured while generating audio
                  setLoading(false);
                  setVoiceGenerationError(true);
                } else {
                  const newCount = decreaseCharCount(charAmount).then(
                    (count) => {
                      setLiveCharCount(count);
                      // after audio is succesfully make,  then we enable the play button
                      setButtonPressed(true);
                    }
                  );
                }
              })
              .catch((error) => {
                setLoading(false);
                setVoiceGenerationError(true);
              });
          } catch (e) {
            console.log(e);
          }
          setTimeout(() => {
            setDownloadButtonDisabled(false);
          }, 3000);
        } else {
          setOutOfCharacters(true);
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
      setSelectedVoiceError(true);
      console.log("NO PRESET SELECTED");
    }
  };
  const TopbarComponent = ({ isValid }) => {
    return isValid ? <TopbarLI /> : <Topbar />;
  };

  const disabled = downloadButtonDisabled ? "disabled" : "";
  const generateButtonDisabled = !hasSelctedPreset ? "disabled" : "";

  return (
    <div className="mainWrapper">
      {loading ? (
        <div className="voiceLab-LoadingContainer">
          <Oval
            height={140}
            width={140}
            color="#8250ff"
            wrapperStyle={{}}
            ariaLabel="oval-loading"
            secondaryColor="#380ca4"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : null}
      <TopbarComponent isValid={pb.authStore.isValid} />
      <div className="home">
        <div className="home-HContainer">
          <h1 className="home-h1">
            AI Celebrity Voice Generator{" "}
            <h2 className="home-h2">Online AI Powered Voice AI Tool</h2>
          </h1>

          <div className="home-greetButtonContainer">
            <label className="home-greetButtonLabel">
              Create your own AI Celebrity Voice with Voice Lab!
            </label>
            <Link
              onClick={ClickedOnCelebMix}
              className="home-greetButton"
              to="/voicelab"
            >
              Try Voice-Lab
            </Link>
          </div>
        </div>
        <div className="home-BakDropContainer">
          <img src={bakdrop} className="home-BakDropImg" />
        </div>
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div className="main-content">
        <form
          className="voiceappContainer"
          onSubmit={handleSubmit(VoiceAppDataSubmit)}
        >
          <div className="voiceapp-text">Choose your AI Celeb-voice</div>
          <div className="voiceapp-VoiceMenuContainer">
            {accountTier === "Free" ? (
              <LockPresetOptions
                handleSelect={handlePresetSelect}
                setSelectedPreset={setSelectedPreset}
              />
            ) : (
              <PresetOptions
                handleSelect={handlePresetSelect}
                setSelectedPreset={setSelectedPreset}
              />
            )}
            <button
              className="voiceapp-ShowMore"
              onClick={() => {
                setMenuVisible(!menuVisible);
                //console.log("Menu visibility:", !menuVisible);
              }}
              type="button"
            >
              Show More
            </button>
          </div>
          {/* 
          <div className="slider-label">Stability</div>

          <Slider
            className="slider"
            trackClassName="slider-track"
            handleClassName="slider-handle"
            defaultValue={50}
            min={0}
            max={100}
            step={5}
            onChange={(value) => setStabilitySliderValue(value)}
          />
          <div className="slider-label">Clarity</div>
          <Slider
            className="slider"
            trackClassName="slider-track"
            handleClassName="slider-handle"
            defaultValue={50}
            min={0}
            max={100}
            step={5}
            onChange={(value) => setSimilaritySliderValue(value)}
          />
          */}
          {menuVisible &&
            (accountTier === "Free" ? (
              <LockPresetMenu
                handleButtonClick={() => setMenuVisible(!menuVisible)}
                handleSelect={handlePresetSelect}
                setSelectedPreset={setSelectedPreset}
                className={`PresetMenu-container ${
                  menuVisible ? "PresetMenu-container-visible" : ""
                }`}
              />
            ) : (
              <PresetMenu
                handleButtonClick={() => setMenuVisible(!menuVisible)}
                handleSelect={handlePresetSelect}
                setSelectedPreset={setSelectedPreset}
                className={`PresetMenu-container ${
                  menuVisible ? "PresetMenu-container-visible" : ""
                }`}
              />
            ))}
          <p
            className={`voiceapp-NotEnoughCharAlert ${
              selectedVoiceError ? "show" : ""
            }`}
          >
            Error No Preset selected
          </p>
          <p
            className={`voiceapp-NotEnoughCharAlert ${
              outOfCharacters ? "show" : ""
            }`}
          >
            Not Enough Characters Left
          </p>
          <p
            className={`voiceapp-NotEnoughCharAlert ${
              voiceGenerationError ? "show" : ""
            }`}
          >
            Error Generating Voice. Try Again
          </p>
          <textarea
            className="voiceapp-inputText"
            placeholder="Unleash the voice of your favorite AI Celeb"
            {...register("textInput", { required: true })}
          />
          {pb.authStore.isValid ? (
            <p style={{ fontFamily: "Metropolis-Medium", color: "white" }}>
              {liveCharCount} Characters Left
            </p>
          ) : null}
          <div className="voiceapp-containerButton">
            <button
              className={`${
                generateGray
                  ? " voiceapp-submitButtonNoPreset"
                  : "voiceapp-submitButton"
              }`}
              type="submit"
            >
              Generate Audio
            </button>
            <button
              type="button"
              disabled={disabled}
              onClick={downloadButtonSubmit}
              className="voiceapp-submitDownload"
            >
              Download
              <img src={download} className="voiceapp-submitDownloadImg" />
            </button>
          </div>
        </form>

        <div className={"home-audioPlayerBackdrop"}>
          <div className="home-audioPlayerContainer">
            <AudioPlayer
              presetImg={selectedPreset.img}
              refrence={audioPlayerRef}
              blobURL={blobURL}
              placeholderImg={placeholderImg}
              ButtonPressed={buttonPressed}
              Isloading={loading}
            ></AudioPlayer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
