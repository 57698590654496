import React from "react";
import { Link } from "react-router-dom";
import normcheckmark from "images/checkmark.webp";
import xmark from "images/xmark.webp";
import "css/pricing.css";
import pb from "UIM";
import createPaymentLink from "./AccountHandling/CreatePaymentLink.js";
import useVerified from "./useVerified.js";
import useLimitPayment from "hooks/useLimitPayment.js";
import createPaymentLink2 from "./AccountHandling/CreatePaymentLink2.js";
import yellowcheckmark from "images/golden_checkmark.webp";
import ReactGA from 'react-ga'

const Tier0 = () => {
  window.location.href = "/signUp";
};
const authPage = () => {
  window.location.href = "/auth";
};
const Tier1 = () => {
  // $2.99

  const priceAPI = "price_1NoT7KChiRgBnfcNljWzoZSc";
  createPaymentLink2(pb.authStore.model.id, 'tier1-subscription-monthly');
  //createPaymentLink(pb.authStore.model.id, priceAPI)
};
const Tier2 = () => {
  // $15.99
  const price2API = "price_1PopTxChiRgBnfcN5u3kiL5x";
  //createPaymentLink(pb.authStore.model.id, price2API)
  createPaymentLink2(pb.authStore.model.id, 'tier2-subscription-monthly');
};
const Tier3 = () => {
  // $24.99
  const price3API = "price_1PopfsChiRgBnfcNkNUkp9ee";
  //createPaymentLink(pb.authStore.model.id, price3API)
  createPaymentLink2(pb.authStore.model.id, 'tier3-subscription-monthly');
};
const Tier4 = () => {
  // $49.99
  const price4API = "price_1NDrneChiRgBnfcNF9kvlSaz";
  //createPaymentLink(pb.authStore.model.id, price4API)
  createPaymentLink2(pb.authStore.model.id, 'tier4-subscription-monthly');
};



//////////////////////////////////////////////////////////
const Tier1Yearly = () => {
  // $32.89

  const priceAPI = "price_1NDrgPChiRgBnfcNRd4CEyUj";
  //createPaymentLink(pb.authStore.model.id, priceAPI)
  createPaymentLink2(pb.authStore.model.id, 'tier1-subscription-yearly');
};
const Tier2Yearly = () => {
  // $160
  const price2API = "price_1PoUnfChiRgBnfcNkuuqrGci";
  //createPaymentLink(pb.authStore.model.id, price2API)
  createPaymentLink2(pb.authStore.model.id, 'tier2-subscription-yearly');
};
const Tier3Yearly = () => {
  // $274.89
  const price3API =   "price_1Popl1ChiRgBnfcN3v3DaGXj";
  createPaymentLink2(pb.authStore.model.id, 'tier3-subscription-yearly');
  //createPaymentLink(pb.authStore.model.id, price3API)
};
const Tier4Yearly = () => {
  // $549.89
  const price4API = "price_1NDricChiRgBnfcNr2xDDnYw";
  createPaymentLink2(pb.authStore.model.id, 'tier4-subscription-yearly');
};

const CustomButton = ({ className, to, href, children, onClick, id, price, tier, available }) => {
  return (
    <button className={className} href={href} onClick={onClick} id={id} price={price} tier={tier} available={available}>
      {children}
    </button>
  );
};

const PricingContainer = ({
  title,
  subtitle,
  description,
  price,
  button,
  features,
  link,
  buttonClass,
  titleClass,
  subtitleClass,
  external,
  PriceClass,
  checkmark,
}) => {
  const { canClick } = useLimitPayment(link);
  let handleClick;
  if (!pb.authStore.isValid) {
    handleClick = Tier0;
  } else if (link === 1 && canClick) {
    handleClick = Tier1;
  } else if (link === 2 && canClick) {
    handleClick = Tier2;
  } else if (link === 3 && canClick) {
    handleClick = Tier3;
  } else if (link === 4 && canClick) {
    handleClick = Tier4;
  } else if (link === 10 && canClick) {
    handleClick = Tier1Yearly;
  } else if (link === 20 && canClick) {
    handleClick = Tier2Yearly;
  } else if (link === 30 && canClick) {
    handleClick = Tier3Yearly;
  } else if (link === 40 && canClick) {
    handleClick = Tier4Yearly;
  } else if (link === 0) {
    handleClick = Tier0;
  }
  return (
    <div className="pricing-container">
      <div>
        <p className={`pricing-title ${titleClass ? titleClass : ""}`}>
          {title}
          <p
            className={`pricing-subtitle ${subtitleClass ? subtitleClass : ""}`}
          >
            {subtitle}
          </p>
        </p>

        <p className="pricing-description">{description}</p>
        <div className="pricing-pricingPerMonthContainer">
          <p className={`pricing-price ${PriceClass ? PriceClass : ""}`}>
            {price}
          </p>
          <p className="pricing-month">/month</p>
        </div>
      </div>
      <CustomButton
        className={`pricing-subButton ${buttonClass ? buttonClass : ""}`}
        id={'super-cool-purchase'}
        available={buttonClass}
        tier={link}
        price={price}
        to={external ? null : link}
        href={external ? link : null}
        onClick={handleClick}
      >
        {button}
      </CustomButton>
      <div className="pricing-containerBottom">
        {features.map((feature, index) => (
          <div
            key={index}
            className="pricing-containerBottomRow"
            style={index === 0 ? { borderTop: "1px gray solid" } : {}}
          >
            <label>{feature.label}</label>
            <img
              src={feature.available ? checkmark : xmark}
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default PricingContainer;
