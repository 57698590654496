import React from "react";
import "../css/home.css";
import "../css/password.css";
import "../css/signupin.css";
import CancelSubscription from "hooks/AccountHandling/cancelSubAreYouSure";
import useGetUserInfo from "hooks/useUserInfo";
const CancelSub = () => {
  const { accountTier } = useGetUserInfo();
  if (accountTier === "Free") {
    window.location.href = "/";
  }
  return (
    <div>
      <CancelSubscription />
    </div>
  );
};

export default CancelSub;
