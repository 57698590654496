import { useEffect, useState } from "react";
import 'css/stripe.css'
import visaSVG from '../../images/cart/visa.svg'
import mastercardSVG from '../../images/cart/mastercard.svg'
import amexSVG from '../../images/cart/amex.svg'
import discoverSVG from '../../images/cart/discover.svg'
import jcbSVG from '../../images/cart/jcb.svg'
import dinersSVG from '../../images/cart/diners.svg'
import uionionpay from '../../images/cart/unionpay.svg'
import exclamation from '../../images/cart/exclamation.jpg'
import cardImage from '../../images/cart/card_image.webp'


const CardInformation = (props) => {
  const handleInputChangeExpireDate = (e) => {
    let value = e.target.value.replace(/\s/g, "").replace(/\//g, ""); // Remove existing spaces and slash
    if (value.length > 2) {
      const mm = value.slice(0, 2);
      const yy = value.slice(2);
      if (mm > 12) {
        // Prevent MM from exceeding 12
        value = "12" + yy;
      }
      if (yy > 31) {
        value = mm + "31";
      }
    }
    const formattedValue = value.replace(/(\d{2})(\d{1,2})/, "$1 / $2"); // Format as MM / YY
    props.setValue("expireDate", formattedValue); // Update the input value using setValue
  };

  const handleInputChangeCardNumber = (e) => {
    const value = e.target.value.replace(/\s/g, ""); // Remove existing spaces
    const formattedValue = value.replace(/(\d{4})/g, "$1 ").trim(); // Add spaces every 4 digits
    props.setValue("cardNumber", formattedValue); // Update the input value using setValue
  };

  const [visibleSpanIndex, setVisibleSpanIndex] = useState(0);

  // Use useEffect to cycle through the spans every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisibleSpanIndex((prevIndex) => (prevIndex + 1) % 4); // Cycle through 0 - 4
    }, 3000); // Change visibility every 1000 milliseconds (1 second)

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="flex flex-col relative">
      <label
        className="pb-1"
        style={{
          fontSize: "13px",
          fontWeight: "500",
          color: "rgba(97,97,97, 0.85)",
          fontFamily: "segoeui-semibold",
        }}
      >
        Card information
      </label>
      {props.errors.cardNumber && (
        <span
          className="absolute right-0"
          style={{
            color: "rgb(220, 39, 39) ",
            fontSize: "11px",
            fontWeight: "500",
            fontFamily: "segoeui-semibold",
          }}
        >
          REQUIRED
        </span>
      )}
      <div className="relative">
        <input
          type="input"
          placeholder="1234 1234 1234 1234"
          {...props.register("cardNumber", { required: "Required" })}
          className="border relative border-none py-2 px-3 text-sm w-full focus:outline-none focus:border-red-800 focus:shadow-xl "
          style={{
            width:'353px',
            boxShadow: props.focusState.cardNumber
              ? "rgba(76, 151, 203, 0.733) 0px 0px 0px 1px, rgba(0, 0, 0, 0.07) 0px 1.10205px 1.30615px 0px, rgba(49, 148, 207, 0.275) 0px 0.10205px 0.153074px 3.5918px"
              : props.isSubmitted || props.errors.cardNumber
              ? "0 0 0 0.5px rgb(220, 39, 39)"
              : "0 0 0 1px rgba(175,175,175,0.3), 0 1px 4px 0px rgba(0,0,0,0.1)",
            fontSize: "14px",
            borderRadius: "6px 6px 0 0",
            height: "20px",
            zIndex: props.focusState.cardNumber ? 10 : 1, // Adjust z-index based on focus state
            fontFamily: "segoeui-semibold",
          }}
          onFocus={() => props.handleFocus("cardNumber")}
          onBlur={() => props.handleBlur("cardNumber")}
          maxLength={19} // Adjusted to accommodate spaces
          onChange={handleInputChangeCardNumber}
        ></input>
        {!props.isSubmitted && (
          <div
            className="absolute h-full flex items-center pr-3 z-20"
            style={{ top: "0", right: "0" }}
          >
            <div className="flex justify-between">
              <div className="pr-1 flex items-center">
                <span className="p">
                  <img src={visaSVG} />
                </span>
              </div>
              <div className="pr-1 flex items-center">
                <span className="">
                  <img src={mastercardSVG} />
                </span>
              </div>
              <div className="pr-1 flex items-center">
                <span className="">
                  <img src={amexSVG} />
                </span>
              </div>
              <div className="pr-0 flex items-center">
                <span
                  className=""
                  style={{
                    opacity: visibleSpanIndex === 0 ? 1 : 0,
                    transition: "opacity .2s",
                  }}
                >
                  <img src={discoverSVG} />
                </span>
                <span
                  className="absolute h-full  visible"
                  style={{
                    top: "29%",
                    opacity: visibleSpanIndex === 1 ? 1 : 0,
                    transition: "opacity .2s",
                    maxWidth: "36px",
                  }}
                >
                  <img src={jcbSVG} />
                </span>
                <span
                  className="absolute h-full visible"
                  style={{
                    top: "29%",
                    opacity: visibleSpanIndex === 2 ? 1 : 0,
                    transition: "opacity .2s",
                    maxWidth: "36px",
                  }}
                >
                  <img src={dinersSVG} />
                </span>
                <span
                  className="absolute h-full visible"
                  style={{
                    top: "29%",
                    opacity: visibleSpanIndex === 3 ? 1 : 0,
                    transition: "opacity .2s",
                    maxWidth: "36px",
                  }}
                >
                  <img src={uionionpay} />
                </span>
              </div>
            </div>
          </div>
        )}
        {props.isSubmitted && (
          <div className="relative">
            <img
              className="absolute z-50 h-3.5 mr-2"
              style={{ bottom: "10px", right: 0 }}
              src={exclamation}
            />
          </div>
        )}
      </div>
      <div className="flex justify-between w-full relative">
        <input
          type="input"
          placeholder="MM / YY"
          {...props.register("expireDate", { required: "Required" })}
          className="border border-none py-2 px-3 text-sm focus:outline-none w-1/2 focus:border-red-800 focus:shadow-xl"
          style={{

            height: "20px",
            boxShadow: props.focusState.expireDate
              ? "rgba(76, 151, 203, 0.733) 0px 0px 0px 1px, rgba(0, 0, 0, 0.07) 0px 1.10205px 1.30615px 0px, rgba(49, 148, 207, 0.275) 0px 0.10205px 0.153074px 3.5918px"
              : props.errors.expireDate
              ? "0 0 0 0.5px rgb(220, 39, 39)"
              : "0 0 0 1px rgba(175,175,175,0.3), 0 1px 4px 0px rgba(0,0,0,0.1)",
            fontSize: "14px",
            borderRadius: "0 0 0 6px",

            zIndex: props.focusState.expireDate ? 10 : 0,
            fontFamily: "segoeui-semibold",
          }}
          onFocus={() => props.handleFocus("expireDate")}
          onBlur={() => props.handleBlur("expireDate")}
          maxLength={7} // Adjusted to accommodate spaces and slash
          onChange={handleInputChangeExpireDate}
        ></input>

        <input
          type="input"
          placeholder="CVC"
          {...props.register("cvc", { required: "Required" })}
          className="border border-none py-2 px-3 text-sm w-1/2 focus:outline-none focus:border-red-800 focus:shadow-xl"
          style={{
            
            height: "20px",
            boxShadow: props.focusState.cvc
              ? "rgba(76, 151, 203, 0.733) 0px 0px 0px 1px, rgba(0, 0, 0, 0.07) 0px 1.10205px 1.30615px 0px, rgba(49, 148, 207, 0.275) 0px 0.10205px 0.153074px 3.5918px"
              : props.errors.cvc
              ? "0 0 0 0.5px rgb(220, 39, 39)"
              : "0 0 0 1px rgba(175,175,175,0.3), 0 1px 4px 0px rgba(0,0,0,0.1)",
            fontSize: "14px",
            borderRadius: "0 0 6px 0",
            
            zIndex: props.focusState.cvc ? 10 : 0,
            fontFamily: "segoeui-semibold",
          }}
          onFocus={() => props.handleFocus("cvc")}
          onBlur={() => props.handleBlur("cvc")}
          maxLength={4}
        ></input>
        <div
          className="absolute h-full flex items-center justify-end z-20"
          style={{ top: "0", right: "0" }}
        >
          <img src={cardImage} className="h-4 pr-3"></img>
        </div>
      </div>
    </div>
  );
};
export default CardInformation;
