import 'css/stripe.css'

const EmailField = (props) => {
  return (
    <div className="flex flex-col relative">
      <label
        className="pb-1"
        style={{
          fontSize: "13px",
          fontWeight: "500",
          color: "rgba(97,97,97, 0.85)",
          fontFamily: "segoeui-semibold",
        }}
      >
        Email
      </label>
      {props.errors.email && (
        <span
          className="absolute right-0"
          style={{
            color: "rgb(220, 39, 39) ",
            fontSize: "11px",
            fontWeight: "500",
            fontFamily: "segoeui-semibold",
          }}
        >
          REQUIRED
        </span>
      )}

      <input
        type="email"
        {...props.register("email", { required: "Required" })}
        className="border border-none py-2 px-3 text-sm w-full focus:outline-none focus:border-red-800 focus:shadow-xl"
        style={{
          width:'353px',
          height: "20px",
          boxShadow: props.focusState.email
            ? "rgba(76, 151, 203, 0.733) 0px 0px 0px 1px, rgba(0, 0, 0, 0.07) 0px 1.10205px 1.30615px 0px, rgba(49, 148, 207, 0.275) 0px 0.10205px 0.153074px 3.5918px"
            : props.errors.email
            ? "0 0 0 0.5px rgb(220, 39, 39)"
            : "0 0 0 1px rgba(175,175,175,0.3), 0 1px 4px 0px rgba(0,0,0,0.1)",
          fontSize: "14px",
          borderRadius: "6px",

          fontFamily: "segoeui-semibold",
        }}
        onFocus={() => props.handleFocus("email")}
        onBlur={() => props.handleBlur("email")}
      />
    </div>
  );
};
export default EmailField;
