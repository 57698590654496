import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
} from "react-router-dom";
import Home from "./Pages/Home";
import Pricing from "./Pages/Pricing";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import TOS from "./Pages/TOS";
import Privacy from "./Pages/Privacy";
import Authenticate from "./Pages/Authenticate";
import User from "./Pages/User";
import Password from "./Pages/Password";
import Guidelines from "./Pages/Guidelines";
import Delete from "Pages/Delete";
import AIeditor from "Pages/aieditor";
import LogOutPage from "Pages/LogOut";
import TestPage from "Pages/Test";
import CancelSub from "Pages/CancelSub";
import SuccPurPage from "Pages/SuccPurPage";
import FailPurPage from "Pages/FailPurPage";
import VoiceLab from "Pages/voiceLab";
import Checkout from "Pages/Checkout";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/checkout/:tier" element={<Checkout />} />
        <Route path="/logout" element={<LogOutPage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/TOS" element={<TOS />} />
        <Route path="/PrivacyPolicy" element={<Privacy />} />
        <Route path="/user" element={<User />} />
        <Route path="/Guidelines" element={<Guidelines />} />
        <Route path="/delete" element={<Delete />} />
        <Route path="/celebmix" element={<AIeditor />} />
        <Route path="/cancelSub" element={<CancelSub />} />
        <Route path="/SuccesfulPurchase" element={<SuccPurPage />} />
        <Route path="/FailPurPage" element={<FailPurPage />} />
        <Route path="/VoiceLab" element={<VoiceLab />} />
      </Routes>
    </Router>
  );
}

export default App;
