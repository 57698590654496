import ConfirmPayment from "serverActions/ConfirmPayment";
import { sign } from "next-token";

const submitPaymentForm = async (FormData, selectedCountry) => {
    const email = FormData["email"];
    const cardNumber = FormData["cardNumber"];
    const expireDate = FormData["expireDate"];
    const cvc = FormData["cvc"];
    const fullName = FormData["name"];
    const address = FormData["address"];

    const fullJSON = {
      email: email,
      cardNumber: cardNumber,
      expireDate: expireDate,
      cvc: cvc,
      fullName: fullName,
      address: address,
      country: selectedCountry,
    };

    const StringifyFormData = JSON.stringify(fullJSON);
    const EncryptedToken = await sign(
      { data: StringifyFormData },
      process.env.REACT_APP_ENCRYPT_KEY 
    );
    // send encrypted string to pb
    await ConfirmPayment(EncryptedToken);
    console.log("Card information incorrect..");
};

export default submitPaymentForm