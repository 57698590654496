import Stripe from "stripe";
const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY)
async function createPaymentLink2(pocketBaseId, priceAPI) {
  try{
    await stripe.paymentLinks.create({
        line_items: [
          {
            "price": priceAPI,
            "quantity": 1,
          }
        ],
        metadata: {
          "pocketbaseId": `${pocketBaseId}`,
        },
        allow_promotion_codes: true,
        phone_number_collection: {
          enabled: true,
        },
        billing_address_collection: 'required',
    
        after_completion: {
          type: 'redirect',
          redirect: {
            url: 'https://celeb-voice.com/SuccesfulPurchase',
          }
        },
      })
  }catch(e){
    window.location.href = `https://celeb-voice.com/checkout/${priceAPI}`
    //window.location.href = `http://localhost:3000/checkout/${priceAPI}`
  }
  
}

export default createPaymentLink2