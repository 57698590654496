const countries = [
  'United States', 'United Kingdom',
  'Afghanistan', 'Albania', 'Algeria',
  'Andorra', 'Angola', 'Antigua and Barbuda',
  'Argentina', 'Armenia', 'Australia',
  'Austria', 'Azerbaijan', 'Bahamas',
  'Bahrain', 'Bangladesh', 'Barbados',
  'Belarus', 'Belgium', 'Belize',
  'Benin', 'Bhutan', 'Bolivia',
  'Bosnia and Herzegovina', 'Botswana', 'Brazil',
  'Brunei', 'Bulgaria', 'Burkina Faso',
  'Burundi', 'Cabo Verde', 'Cambodia',
  'Cameroon', 'Canada', 'Central African Republic',
  'Chad', 'Chile', 'China',
  'Colombia', 'Comoros', 'Congo, Democratic Republic of the',
  'Congo, Republic of the', 'Costa Rica', 'Côte d\'Ivoire',
  'Croatia', 'Cuba', 'Cyprus',
  'Czech Republic', 'Denmark', 'Djibouti',
  'Dominica', 'Dominican Republic', 'East Timor (Timor-Leste)',
  'Ecuador', 'Egypt', 'El Salvador',
  'Equatorial Guinea', 'Eritrea', 'Estonia',
  'Eswatini', 'Ethiopia', 'Fiji',
  'Finland', 'France', 'Gabon',
  'Gambia', 'Georgia', 'Germany',
  'Ghana', 'Greece', 'Grenada',
  'Guatemala', 'Guinea', 'Guinea-Bissau',
  'Guyana', 'Haiti', 'Holy See',
  'Honduras', 'Hungary', 'Iceland',
  'India', 'Indonesia', 'Iran',
  'Iraq', 'Ireland', 'Israel',
  'Italy', 'Jamaica', 'Japan',
  'Jordan', 'Kazakhstan', 'Kenya',
  'Kiribati', 'Korea, North', 'Korea, South',
  'Kosovo', 'Kuwait', 'Kyrgyzstan',
  'Laos', 'Latvia', 'Lebanon',
  'Lesotho', 'Liberia', 'Libya',
  'Liechtenstein', 'Lithuania', 'Luxembourg',
  'Madagascar', 'Malawi', 'Malaysia',
  'Maldives', 'Mali', 'Malta',
  'Marshall Islands', 'Mauritania', 'Mauritius',
  'Mexico', 'Micronesia, Federated States of', 'Moldova',
  'Monaco', 'Mongolia', 'Montenegro',
  'Morocco', 'Mozambique', 'Myanmar (Burma)',
  'Namibia', 'Nauru', 'Nepal',
  'Netherlands', 'New Zealand', 'Nicaragua',
  'Niger', 'Nigeria', 'North Macedonia',
  'Norway', 'Oman', 'Pakistan',
  'Palau', 'Panama', 'Papua New Guinea',
  'Paraguay', 'Peru', 'Philippines',
  'Poland', 'Portugal', 'Qatar',
  'Romania', 'Russia', 'Rwanda',
  'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines',
  'Samoa', 'San Marino', 'Sao Tome and Principe',
  'Saudi Arabia', 'Senegal', 'Serbia',
  'Seychelles', 'Sierra Leone', 'Singapore',
  'Slovakia', 'Slovenia', 'Solomon Islands',
  'Somalia', 'South Africa', 'South Sudan',
  'Spain', 'Sri Lanka', 'Sudan',
  'Suriname', 'Sweden', 'Switzerland',
  'Syria', 'Taiwan', 'Tajikistan',
  'Tanzania', 'Thailand', 'Togo',
  'Tonga', 'Trinidad and Tobago', 'Tunisia',
  'Turkey', 'Turkmenistan', 'Tuvalu',
  'Uganda', 'Ukraine', 'United Arab Emirates',
  'Uruguay', 'Uzbekistan',
  'Vanuatu', 'Vatican City', 'Venezuela',
  'Vietnam', 'Yemen', 'Zambia',
  'Zimbabwe'
];

  
  export default countries


