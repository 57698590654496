import React, { useState, useEffect } from "react";
import "../../css/home.css";
import "../../css/password.css";
import "../../css/signupin.css";
import { set, useForm } from "react-hook-form";
import LogoObject from "images/logoObject.png";
import { Link } from "react-router-dom";
import CancelSubscriptionPart2 from "./cancelSubPart2.js";
import useGetUserInfo from "../useUserInfo.js";

export default function CancelSubscription() {
  const { accountTier } = useGetUserInfo();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 0);
  }, []);

  const [showComponent, setShowComponent] = React.useState(true);
  const [showComponent2, setShowComponent2] = useState(false);
  const toggleComponent = () => {
    setShowComponent(!showComponent);
  };
  
  const CancelSubscriptionSubmit = async (DeleteAccData) => {
    toggleComponent();
    setShowComponent2(true);
  };

  //{showComponent && <DeletePart2 />}
  return (
    <div>
      {showComponent && (
        <div className="mainWrapper">
          <Link to="/" className="signInOutTopbar">
            <img src={LogoObject} className="signInOutTopbarLogo"></img>
          </Link>
          <div className={`signInOutForm${loaded ? " loaded" : ""}`}>
            <form
              className={`signForm-container${loaded ? " loaded" : ""}`}
              onSubmit={handleSubmit(CancelSubscriptionSubmit)}
            >
              <p className="signForm-text" style={{ textAlign: "center" }}>
                Cancel Your Subscription?
              </p>
              <p
                style={{
                  textAlign: "center",
                  color: "white",
                  fontFamily: "Metropolis-Medium",
                }}
              >
                Are you sure you want to cancel your {accountTier} subscription?
                The only way to reverse this action is to subscribe to another
                plan.
              </p>

              <button type="submit" className="signForm-buttonLogin">
                I am sure
              </button>
            </form>
          </div>
        </div>
      )}
      {showComponent2 && <CancelSubscriptionPart2 />}
    </div>
  );
}
