import React, { useState, useEffect } from "react";
import "../css/home.css";
import "../css/signupin.css";
import "../css/privacy.css";
import LogoObject from "images/logoObject.png";
import { Link } from "react-router-dom";
import Footer from "hooks/Footer";

export default function TOS() {
  return (
    <div className="mainWrapper">
      <Link to="/" className="signInOutTopbar">
        <img src={LogoObject} className="signInOutTopbarLogo"></img>
      </Link>
      <div className="text">
        <h2>License to Use Our Service</h2>
        <p>
          a. We grant you a non-exclusive, non-transferable license to access
          and use our service, subject to these terms of service. b. This
          license covers only the use of the celebrity AI voices provided by the
          website, and not any other intellectual property or third-party
          content.
        </p>
        <h2>Prohibited Uses</h2>
        <p>
          a. You may not use our service for any illegal or unauthorized
          purpose, or in a way that violates the rights of others. b. You may
          not use our service to harass, threaten, or defame anyone, or to
          distribute malware or spam. c. You may not use our service in a way
          that would infringe on any copyright, trademark, or other proprietary
          rights of others.
        </p>
        <h2>Ownership of Intellectual Property</h2>
        <p>
          a. We own all rights, title, and interest in and to our service,
          including any content, software, or intellectual property created by
          us or our licensors. b. You may not use our service or any of its
          contents in a way that would infringe on our intellectual property
          rights or those of our licensors. c. You retain ownership of any text
          you create using our celebrity AI voices, but grant us a
          non-exclusive, worldwide, royalty-free license to use, modify, and
          distribute that content for the purpose of providing the service.
        </p>
        <h2> Liability Limitations</h2>
        <p>
          a. We are not liable for any damages arising from your use of our
          service, including any direct, indirect, incidental, or consequential
          damages. b. You agree to indemnify and hold us harmless from any
          claims, damages, or expenses arising from your use of our service.
        </p>
        <h2> Privacy Policy</h2>
        <p>
          a. We collect and process personal information in accordance with our
          privacy policy, which is incorporated into these terms of service.
          Modification of Terms a. We may modify these terms of service at any
          time by posting revised terms on our website. b. Your continued use of
          our service after the posting of revised terms constitutes your
          acceptance of the modified terms.
        </p>
        <h2>Termination</h2>
        <p>
          a. We may terminate your access to our service at any time, without
          notice or liability, for any reason. b. Upon termination, you must
          cease using our service and destroy any copies of our content in your
          possession.
        </p>
        <h2>Governing Law and Jurisdiction</h2>
        <p>
          a. These terms of service are governed by and construed in accordance
          with the laws of the State of Missouri without giving effect to any
          principles of conflicts of law. b. Any dispute arising from these
          terms of service or your use of our service shall be resolved
          exclusively in the state or federal courts located in Kansas City,
          Missouri.
        </p>
        <h2>User Content</h2>
        <p>
          a. You retain ownership of any text you create using our celebrity AI
          voices. b. By submitting user content to our website, you grant us a
          non-exclusive, worldwide, royalty-free license to use, copy, modify,
          and distribute your user content for the purpose of providing and
          promoting our service. c. You represent and warrant that you have the
          necessary rights to grant us the license described in section 9.b. d.
          We reserve the right to remove any user content that violates these
          terms of service or that we deem inappropriate, without notice or
          liability to you. e. You agree to indemnify and hold us harmless from
          any claims, damages, or expenses arising from your user content,
          including any claims of infringement or violation of intellectual
          property rights. f. We do not endorse or guarantee the accuracy,
          completeness, or reliability of any user content, and we are not
          responsible for any loss or damage arising from your reliance on such
          user content. g. We may use your user content to improve our service,
          including by using it to train and improve our celebrity AI voices. h.
          You acknowledge that any user content you submit to our website may be
          accessed by other users of our service, and that we are not
          responsible for any misuse or unauthorized access of your user content
          by other users. i. You may remove your user content from our website
          at any time, but we may retain copies of your user content for backup,
          archival, or legal purposes.
        </p>
        <h2>Refunds</h2>
        <p>
          a. All purchases made on our service are non-refundable. Once you have
          made a purchase, you will not be eligible for a refund under any
          circumstances.
        </p>
      </div>
      <Footer />
    </div>
  );
}
