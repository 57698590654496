import React, { useState } from "react";
import "css/main.css";
import "css/footer.css";
import { Link } from "react-router-dom";
import face from "images/face.webp";
import insta from "images/insta.webp";
import tiktok from "images/tiktok.webp";
import twitter from "images/twitter.webp";
import discord from "images/discord.webp";
import youtube from "images/youtube.webp";

export default function Footer() {
  return (
    <div className="footer-Container">
      <div className="footer-textContainer">
        <Link className="footer-text" to="/TOS">
          Terms of Service
        </Link>
        <Link className="footer-text" to="/Guidelines">
          Guide Lines
        </Link>
        <Link className="footer-text" to="/PrivacyPolicy">
          Privacy Policy
        </Link>
      </div>

      <div className="footer-socialContainer">
              {/* 
        <a
          className="footer-imgContainer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={face} style={{ maxHeight: "100%", maxWidth: "100%" }} />
        </a>
        <a
          className="footer-imgContainer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={insta} style={{ maxHeight: "100%", maxWidth: "100%" }} />
        </a>
        <a
          className="footer-imgContainer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={tiktok} style={{ maxHeight: "100%", maxWidth: "100%" }} />
        </a>
        <a
          className="footer-imgContainer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twitter} style={{ maxHeight: "100%", maxWidth: "100%" }} />
        </a>
        <a
          className="footer-imgContainer"
          target="_blank"
          rel="noopener noreferrer"
          href="https://discord.gg/EJUf57G6rq"
        >
          <img src={discord} style={{ maxHeight: "100%", maxWidth: "100%" }} />
        </a>
        <a
          className="footer-imgContainer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={youtube} style={{ maxHeight: "100%", maxWidth: "100%" }} />
        </a>
              */}
      </div>
    </div>
  );
}
