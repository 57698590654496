import pb2 from 'UIM2';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import confirmCardPayment from '@stripe/react-stripe-js'

async function SecureCheckout(data) {
  const elements = data[1]
  const handleSubmit = async () => {
    if (!elements) return;
    
    try {
      const cardElement = data
      const MakePayment = await confirmCardPayment({
        type: 'card',
        card: cardElement,
      });
    } catch (err) {
        const failureID = data
        const Failure = await pb2.collection('failureRecordsCV').create({
            data:failureID
        })
    }
  };
  handleSubmit()
}
export default SecureCheckout
