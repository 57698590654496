//Topbar.js
import React from "react";
import "css/main.css";
import "css/topBar.css";
import "css/BurgerMenu.css";
import logotext from "../images/logo.png";
import logoObject from "../images/logoObject.png";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import ClickedOnCelebMix from "js/celeb-mix-analytics.js";

function BurgerMenu() {
  return (
    <Menu className="bm-menu">
      <Link className="menu-item" to="/signin">
        Sign In
      </Link>
      <Link className="menu-item" to="/pricing">
        Pricing
      </Link>
      <Link onClick={ClickedOnCelebMix} className="menu-item" to="/celebmix">
        Celeb-Mix
      </Link>
      <Link onClick={ClickedOnCelebMix} className="menu-item" to="/voicelab">
        Try Voice Lab
      </Link>
    </Menu>
  );
}

export default function Topbar() {
  return (
    <div className="topbarContiner">
      <div className="topbar">
        <div className="topbar-containerSignIn">
          <BurgerMenu />
          <Link to="/pricing">
            <button className="topbar-pricingButton">Pricing</button>
          </Link>
          <Link to="/celebmix">
            <button onClick={ClickedOnCelebMix} className="topbar-pricingButton">Try Celeb-Mix</button>
          </Link>
          <Link to="/voicelab">
            <button onClick={ClickedOnCelebMix} className="topbar-pricingButton">Try Voice Lab</button>
          </Link>
        </div>
        <Link className="topbar-containerLogo" to="/">
          <img src={logotext} style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </Link>
        <Link className="SMtopbar-containerLogo" to="/">
          <img
            src={logoObject}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Link>
        <div className="topbar-containerSignUp">
          <Link to="/signin">
            <button className="topbar-pricingButton">Log In</button>
          </Link>
          <a href="/signup">
            <button className="topbar-SignUpButton">Sign Up</button>
          </a>
        </div>
      </div>
    </div>
  );
}
