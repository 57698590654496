// SignIn.jsx
import React, { useEffect, useState } from "react";
import "../css/main.css";
import "../css/signupin.css";
import LogoObject from "images/logoObject.png";
import { set, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import useVerified from "hooks/useVerified.js";
import makeTier0Account from "js/newFreeSubscription";
import pb from "UIM";
import googleImg from "images/google.webp";
import { Oval } from "react-loader-spinner";
import { isSafari, isIOS } from "react-device-detect";

export default function SignIn() {
  const [buttonText, setButtonText] = useState("Continue");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [SafariIphone, setSafariIphone] = useState(false);
  const navigator = useNavigate();

  useEffect(() => {
    setLoaded(true);
    // if user is logged in, then redirect to home page
    if (pb.authStore.isValid) navigator("/");
  }, []);

  useEffect(() => {
    if (isSafari && isIOS) {
      setSafariIphone(true);
      console.log("are using iphone");
    } else {
      setSafariIphone(false);
      console.log("not using iphone");
    }
  }, []);

  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
    setPasswordVisible(!passwordVisible);
  };

  //////////////////////////////////////////////////////////////////////////

  const [error, setError] = useState(false);
  const { isVerified } = useVerified(); // verified hook, stores a boolean for if the users account is verified

  const {
    // username password form
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  
  const [isLoading, setIsLoading] = useState(false); // loading use state

  //sign in function
  const SignInDataSubmit = async (SignInData) => {
    setError(false);
    setIsLoading(false);
    setButtonText("Continue");
    try {
      setIsLoading(true);
      setButtonText("Loading...");
      // tries to authenticate username and pass field with PB
      const authData = await pb
        .collection("users")
        .authWithPassword(SignInData.email, SignInData.password);
      //if successful navigate to home page
      if (pb.authStore.isValid) {
        setIsLoading(false);
        setButtonText("Continue");
        navigator("/");
      } else {
        setButtonText("Continue");
        setIsLoading(false);
        console.log("Not Logged In!");
      }
    } catch (e) {
      setButtonText("Continue");
      setIsLoading(false);
      //if not, then set Error to true, will promp user with Incorrect username or password message
      console.log("User not found!");
      setError(true);
    }
    setButtonText("Continue");

    setIsLoading(false);
  };

  const [loaded, setLoaded] = useState(false);

  const GoogleOauthButtonSubmit = async () => {
    setLoaded(true);
    setError(false)
    try {
      const authData = await pb
        .collection("users")
        .authWithOAuth2({ provider: "google" });
      setIsLoading(true);
      const result = await makeTier0Account(pb.authStore.model.id);
      setLoaded(false);
      setTimeout(() => {
        navigator("/");
        setIsLoading(false);
      }, 2000);
    } catch (e) {
      //error
      setIsLoading(false);
      setError(true);
    }

    /*
    
    */
  };

  return (
    <div className="mainWrapper">
      {isLoading ? (
        <div className="voiceLab-LoadingContainer">
          <Oval
            height={140}
            width={140}
            color="#8250ff"
            wrapperStyle={{}}
            ariaLabel="oval-loading"
            secondaryColor="#380ca4"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : null}
      <Link to="/" className="signInOutTopbar">
        <img src={LogoObject} className="signInOutTopbarLogo"></img>
      </Link>
      <div className={`signInOutForm${loaded ? " loaded" : ""}`}>
        <form
          className={`signForm-container${loaded ? " loaded" : ""}`}
          onSubmit={handleSubmit(SignInDataSubmit)}
        >
          <p className="signForm-text">Login</p>
          <input
            type="text"
            placeholder="Email"
            className="signForm-textInput"
            {...register("email")}
          ></input>

          <input
            type={passwordVisible ? "text" : "password"}
            placeholder="Password"
            className="signForm-textInput"
            {...register("password")}
          ></input>
          <button
            type="button"
            onClick={handlePasswordVisibilityToggle}
            className="signIn-buttonPasswordToggle"
          >
            {showPassword ? "Hide Password" : "Show Password"}
          </button>
          <button type="submit" className="signForm-buttonLogin">
            {buttonText}
          </button>
          <p
            className="signForm-failureText"
            style={{ display: error ? "block" : "none" }}
          >
            Incorrect email or password
          </p>

          <Link to="/signup" className="signForm-linkSign">
            Need an account?
          </Link>
          {!SafariIphone ? (
            <button
              className="signForm-GoogleOauth"
              onClick={GoogleOauthButtonSubmit}
              type="button"
            >
              <img
                src={googleImg}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
                className="signForm-GoogleOauthImg"
              />
              <p className="Googletext">Continue with Google</p>
            </button>
          ) : null}

          {SafariIphone ? <p className="note-Text"></p> : null}
        </form>
      </div>
    </div>
  );
}
