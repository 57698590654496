import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import 'css/stripe.css'
import submitPaymentForm from "../functions/submitPaymentForm";
import BillingAddressComtainer from "../components/PayWithCard/BillingAddressContainer";
import CardHolderNameField from "../components/PayWithCard/CardHolderName";
import CardInformation from "../components/PayWithCard/CardInformation";
import EmailField from "../components/PayWithCard/EmailField";
import SubmitFormButton from "../components/PayWithCard/SubmitFormButton";
import { Suspense } from "react";
import countries from "../countriesList";
import useIsDesktop from "../hooks/useMediaQuery";
import { useParams } from "react-router-dom";
import getSubscriptionDetailsWithParams from "functions/getSubscriptionDetailsWithParams";

const ProductPreview = React.lazy(() => import('../components/ProductPreview/ProductPreview'));

export default function Checkout(){
  const { tier } = useParams();
  const productDetails = getSubscriptionDetailsWithParams(tier)
  if (!productDetails){
    window.location.href = `https://celeb-voice.com/pricing`
  }
  const [ParamsLoaded, setParamsLoaded] = useState(false)
  
  const [price, setPrice] = useState("");
  const [topLabel, setTopLabel] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    // Parse the current URL
    const urlParams = new URLSearchParams(window.location.search);
    if (productDetails && !ParamsLoaded){
      setParamsLoaded(true)
    }
    // Get the specific query parameters
    setPrice(urlParams.get("price") || "0.00");
    setTopLabel(urlParams.get("topLabel") || "0");
    setType(urlParams.get("type") || "null");
  }, [ParamsLoaded, productDetails]);

  //const router = useRouter();
  //const price = router.query?.price ?? "defaultPrice"; // Replace 'defaultPrice' with an appropriate default value
  //const followers = router.query?.followers ?? "defaultFollowers"; // Replace 'defaultFollowers' with an appropriate default value
  const isDesktop = useIsDesktop();
  

  const [focusState, setFocusState] = useState({
    email: false,
    cardNumber: false,
    expireDate: false,
    cvc: false,
    name: false,
    countrylist: false,
    address: false,
  });
  // Handler to set focus
  const handleFocus = (field) => {
    setFocusState({ ...focusState, [field]: true });
  };

  // Handler to remove focus
  const handleBlur = (field) => {
    setFocusState({ ...focusState, [field]: false });
  };

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [isFormValid, setIsFormValid] = useState(false);

  const watchAllFields = watch();

  useEffect(() => {
    // Check if all fields have at least one character
    const isAllFieldsFilled = Object.values(watchAllFields).every(
      (fieldValue) => fieldValue && fieldValue.trim().length > 0
    );
    setIsFormValid(isAllFieldsFilled);
  }, [watchAllFields]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  
  
  return (
    <main
      className="h-screen "
      style={{
        fontFamily: "Segoe UI",
      }}
    >
      <div className="w-full flex justify-center">
        <div className="flex flex-row h-screen w-screen max-w-4xl">
        <Suspense fallback={<div>Loading...</div>}>
            <ProductPreview
              ProductTitle = {productDetails['title']}
              ProductPrice = {productDetails['price']}
              ProductImage = {productDetails['image']}
              Description = {productDetails['desc']}
            />
        </Suspense>

          <div style={{
            width: '50%',
            marginLeft: '160px'

            }} 
            >
            <form
              onSubmit={handleSubmit((formdata) => {
                submitPaymentForm(formdata, selectedCountry);
                setIsSubmitted(true);
              })}
              className="flex flex-col  justify-between py-4"
              style={{
                height: "560px",
                
              }}
              noValidate={true}
            >
              {isDesktop && (
                <h1
                  style={{
                    marginTop: "57px",
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "rgba(48,48,48,0.89)",
                    fontFamily: "segoeui-semibold",
                  }}
                >
                  Pay with card
                </h1>
              )}

              <EmailField
                errors={errors}
                register={register}
                focusState={focusState}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
              />

              <CardInformation
                errors={errors}
                register={register}
                focusState={focusState}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                setValue={setValue}
                isSubmitted={isSubmitted}
              />

              <CardHolderNameField
                errors={errors}
                register={register}
                focusState={focusState}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
              />

              <BillingAddressComtainer
                errors={errors}
                focusState={focusState}
                register={register}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                selectedCountry={selectedCountry}
                handleCountryChange={handleCountryChange}
              />

              <SubmitFormButton isFormValid={isFormValid} />
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

