// SignUp.jsx
import React, { useEffect, useState } from "react";
import "../css/main.css";
import "../css/signupin.css";
import LogoObject from "images/logoObject.png";
import { set, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import googleImg from "images/google.webp";
import pb from "UIM";
import makeTier0Account from "js/newFreeSubscription";
import { Oval } from "react-loader-spinner";
import { isSafari, isIOS } from "react-device-detect";
import { tr } from "date-fns/locale";

// SignUp React App
export default function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [SafariIphone, setSafariIphone] = useState(false);

  useEffect(() => {
    if (isSafari && isIOS) {
      setSafariIphone(true);
      console.log("are using iphone");
    } else {
      setSafariIphone(false);
      console.log("not using iphone");
    }
  }, []);

  const navigator = useNavigate(); // navigator function, used to redirect to another page
  const [errorText, setErrorText] = useState(
    "There was a problem creating your account"
  );
  const [isLoading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoaded(true)
    // if user is logged in, then redirect to home page
    if (pb.authStore.isValid) navigator("/");
  }, []);


  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
    setPasswordVisible(!passwordVisible);
  };

  const {
    // username password form
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const SignUpDataSubmit = async (SignUpData) => {
    setLoading(false);
    setError(false);
    const email = SignUpData.email;
    const password = SignUpData.password;
    const confirm = SignUpData.Confpassword;

    if (password !== confirm) {
      setErrorText("Passwords do not match");
      setError(true);
      setLoading(false);
    } else if (password.length < 8 || confirm.length < 8) {
      setErrorText("Password must be at least 8 characters long");
      setError(true);
      setLoading(false);
    } else {
      try {
        setLoading(true);
        setError(false);
        const data = {
          email,
          password: password,
          passwordConfirm: confirm,
        };
        const createdUser = await pb.collection("users").create(data);
        setLoading(true);

        try {
          // log in with new creds
          const authData = await pb
            .collection("users")
            .authWithPassword(SignUpData.email, SignUpData.password);

          if (pb.authStore.isValid) {
            const result = await makeTier0Account(pb.authStore.model.id);
            navigator("/");
          }
        } catch (e) {
          setLoading(false);
          setErrorText(
            "There was a problem logging in to your created account"
          );
          setError(true);
          console.log(e);
        }
      } catch (e) {
        setLoading(false);
        setErrorText("There was a problem creating your account");
        setError(true);
        console.log(e);
      }
    }
  };

  const GoogleOauthButtonSubmit = async () => {
    setLoaded(true);
    setError(false)
    try {
      const authData = await pb
        .collection("users")
        .authWithOAuth2({ provider: "google" });
      setLoading(true);
      const result = await makeTier0Account(pb.authStore.model.id);
      setLoaded(false)
      setTimeout(() => {
        navigator("/");
        setLoading(false);
      }, 2000);
    } catch (e) {
      setLoading(false);
      setError(true)
      console.log(e)
    }

  };
  return (
    <div className="mainWrapper">
      {isLoading ? (
        <div className="voiceLab-LoadingContainer">
          <Oval
            height={140}
            width={140}
            color="#8250ff"
            wrapperStyle={{}}
            ariaLabel="oval-loading"
            secondaryColor="#380ca4"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : null}
      <Link to="/" className="signInOutTopbar">
        <img src={LogoObject} className="signInOutTopbarLogo"></img>
      </Link>
      <div className={`signInOutForm${loaded ? " loaded" : ""}`}>
        <form
          className={`signForm-container${loaded ? " loaded" : ""}`}
          onSubmit={handleSubmit(SignUpDataSubmit)}
        >
          <p className="signForm-text">Sign Up</p>

          <input
            type="text"
            placeholder="Email"
            className="signForm-textInput"
            {...register("email")}
          ></input>
          <input
            type={passwordVisible ? "text" : "password"}
            placeholder="Password"
            className="signForm-textInput"
            {...register("password")}
          />

          <input
            type={passwordVisible ? "text" : "password"}
            placeholder="Confirm Password"
            className="signForm-textInput"
            {...register("Confpassword")}
          ></input>
          <button
            type="button"
            onClick={handlePasswordVisibilityToggle}
            className="signIn-buttonPasswordToggle"
          >
            {showPassword ? "Hide Passwords" : "Show Passwords"}
          </button>

          <button
            type="submit"
            disabled={isLoading}
            className="signForm-buttonLogin"
          >
            {isLoading ? "Loading..." : "Continue"}
          </button>
          <p
            className="signForm-failureText"
            style={{ display: error ? "block" : "none" }}
          >
            Incorrect email or password
          </p>

          <Link to="/signin" className="signForm-linkSign">
            Already Have an Account?
          </Link>
          {!SafariIphone ? (
            <button
              className="signForm-GoogleOauth"
              onClick={GoogleOauthButtonSubmit}
              type="button"
            >
              <img
                src={googleImg}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
                className="signForm-GoogleOauthImg"
              />
              <p className="Googletext">Continue with Google</p>
            </button>
          ) : null}
          {SafariIphone ? <p className="note-Text"></p> : null}
        </form>
      </div>
    </div>
  );
}
