import { useRef } from "react";
import { useState } from "react";
import { newGetVoiceID } from "js/newGetVoiceID.js";

function combineAudioBlobs(audioBlobs) {
  return new Promise((resolve, reject) => {
    let totalLength = 0;
    let combinedBlob = new Blob();
    let blobsProcessed = 0;

    audioBlobs.forEach((blob, index) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const arrayBuffer = event.target.result;
        const byteLength = arrayBuffer.byteLength;
        combinedBlob = new Blob([combinedBlob, arrayBuffer], {
          type: "audio/wav",
        });
        totalLength += byteLength;
        blobsProcessed++;

        if (blobsProcessed === audioBlobs.length) {
          // Update the audio length in the header
          const audioLengthTo32Bit = (n) => [
            n & 255,
            (n >> 8) & 255,
            (n >> 16) & 255,
            (n >> 24) & 255,
          ];
          const audioLength = totalLength - 8;
          const header = new Uint8Array(audioLengthTo32Bit(audioLength));
          const headerArray = new Uint8Array(44);
          headerArray.set(header);
          combinedBlob = new Blob([combinedBlob, headerArray.buffer], {
            type: "audio/wav",
          });

          resolve(combinedBlob);
        }
      };
      reader.readAsArrayBuffer(blob);
    });
  });
}

const TimeLineToSpeech = (stability, similarity) => {
  stability = stability * 0.01;
  similarity = similarity * 0.01;
  const newStability = stability.toFixed(2);
  const newSimilarity = similarity.toFixed(2);
  const [blobURL, setBlobURL] = useState("null"); // used for downloading audio
  const audioPlayerRef = useRef();

  const getVoiceId = (voicePreset) => {
    // function that takes in the name key attached to the voice id and returns the id
    return 0;
  };

  const downloadAudio = () => {
    const link = document.createElement("a");
    link.download = "celeb-voice-audio-download.mp3";
    link.href = blobURL;
    link.click();
    URL.revokeObjectURL(blobURL);
    console.log("Audio Downloaded");
  };

  const handleTextToSpeech = async (timeline) => {
    console.log("starting voice generation");
    let audioArray = [];

    for (let i = 0; i < timeline.length; i++) {
      const voice_ID_and_api_key = newGetVoiceID(timeline[i]["option"]);
      const voiceId = voice_ID_and_api_key[0];
      const apiKey = voice_ID_and_api_key[1];
      const textin = timeline[i]["TextInput"];

      /////////////////////////////////////////////////////////////
      const headers = {
        "Content-Type": "application/json",
        "xi-api-key": apiKey,
      };

      const url = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`; //url to api call

      const data = {
        text: textin,
        voice_settings: {
          stability: Number(newStability), // this number is a percentage so must be less than 1
          similarity_boost: Number(newSimilarity),
        },
      };

      const response = await fetch(url, {
        // response is the return data of the fetch
        method: "POST",
        headers,
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const audioBlob = await response.blob(); // raw audio data
        audioArray.push(audioBlob);
      } else {
        console.log("Error Occured while generating speech"); // if the response failed
        return "failed";
      }
    } //at the end of the for loop
    //audioPlayerRef.current.src = finalURL; // need to use this line to set the audioPlayerRef to the final conversation audio
    // combine audio from url array
    const audioBlobs = audioArray; // Array of audio Blobs
    combineAudioBlobs(audioBlobs)
      .then((combinedBlob) => {
        const blob = new Blob([combinedBlob], { type: "audio/mp3" });
        const audioUrl = URL.createObjectURL(blob);
        setBlobURL(audioUrl);
        audioPlayerRef.current.src = audioUrl;
        console.log("Audio generated");
      })
      .catch((error) => {
        console.error("Error:", error);
        return "failed";
      });
  };

  return {
    handleTextToSpeech,
    audioPlayerRef,
    getVoiceId,
    downloadAudio,
  };
};
export default TimeLineToSpeech;
