import React from "react";
import "css/main.css";
import "css/Tutorial.css";
import XButonn from "images/xcircle.webp";
import addVoice from "images/addVoice.webp";

export default function Tutorial({closeMenu}) {
  
  return (
    <div className="Tutorial-Container">
      <div className="Tutorial-Container">
        <div className="Tutorial-Content">
          <button className="Tutorial-XButonContainer" onClick={() => closeMenu()} type="button">
           <img className="Tutorial-XButon" src={XButonn} />
          </button>
          <iframe
            className="Tutorial-Video"
            src="https://www.youtube.com/embed/wTtPZ8lHTOg?autoplay=1&controls=1"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="Tutorial Video"
          />
        </div>
      </div>
    </div>
  );
}
