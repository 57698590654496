const marVoiceMap = {

};
const daveVoiceMap = {
  aoc: "YcdBaeNYOgPk9FY6Isdx",
  neilDegrasse: "oZlgIrcfwLks6It1sVyy",
  hasan: "unT8FvGZTqLUz3kUs0ai",
  adinRoss: "mej0ftK46UGLiQL8T80m",
  benShapiro: "6kivksLsBpjvzON7clWE",
  xqc: "QM174e0KVwMB0zQc1erj",
  donald: "nmmX4YruleI9kVl84Acm",
  joeRogan: "7o6LTVITZaqFdojtV38K",
  davidGoggins: "yGhFcrZSLXR6izqT3UY0",
  johnnyDepp: "87vjaK4IQNkoQ8tZYUuw",
  andrewTate: "Rgm4L8JMXdy7DZnmRcmm",
  biden: "OAgTFxWZwewoV3CXFoEi",
  obama: "TX4MNCpsKpuw6eq2VP56",
  iceSpice: "RHCmIhrb6EmFrDjgvClP",
  billGates: "NXrzjCyzYzTKLVdvwlhq",
  elonMusk: "rDiT7uBBGQnG528PcTJc",
  morganFreeman: "fLMIBVfeuQy71ubuTjy1",
  steveJobs: "ZLDlru7EwMMGKzEE4yfV",
  ye: "OidRDPkhiovaeiRVw4rg",
  queen: "IKtSGCAGSxmsoIxD5nJu",
};

export { marVoiceMap, daveVoiceMap };
