import React, { useState, useEffect } from "react";
import "../css/SuccPurPage.css";
import pb from "UIM";
import TopbarLI from "hooks/TopbarLI.js";
import Footer from "hooks/Footer";
import checkmark from "images/checkmark.webp";

export default function SuccPurPage() {
  return (
    <div className="mainWrapper">
      <TopbarLI />
      <div className="succPurPage-wrapper">
        <h1 className="succPurPage-topttext">Thank you for your purchase!</h1>
        <div className="succPurPage-Centerbox">
          <img src={checkmark} className="succPurPage-check" />
          <p className="Centerbox-text">
            Success
            <p className="Centerbox-under">We received your purchase!</p>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
