//AudioPlayer.js
import React, { useState, useRef, useEffect, forwardRef } from "react";
import "../css/MobileAP.css";
import playImage from "../images/Play.webp";
import pauseImage from "../images/Pause.webp";
import placeholderImg from "../images/logoObject.png";
import VolMax from '../images/VolMax.webp'
import VolMin from '../images/VolLow.webp'
import download from "../images/download.webp";
import { Oval } from "react-loader-spinner";
import Xmark from "images/xmark.webp";
import ArrowDown from "images/ArrowDown.webp";

function MobileAudioPlayer(props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayerReff = props.refrence; // audio ref object  // audio ref object
  const [trackProgress, setTrackProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1.0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let BlobURL = props.blobURL
  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    audioPlayerReff.current.volume = newVolume;
    setVolume(newVolume);
  };

  const handlePlayPause = () => {
    console.log("press")
    // Check if audioPlayerReff and audioPlayerReff.current are defined
    if (audioPlayerReff && audioPlayerReff.current) {
      if (isPlaying) {
        audioPlayerReff.current.pause();
      } else {

        audioPlayerReff.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleScrub = (e) => {
    //function gets called when audio scrubber is
    const newCurrentTime =
      (e.target.value / 100) * audioPlayerReff.current.duration;
    audioPlayerReff.current.currentTime = newCurrentTime;
    setTrackProgress(e.target.value);
  };

  useEffect(() => {
    if (BlobURL) {
      console.log("cool animation")// play cool animation here
    }

    setTrackProgress(0);
    const audio = audioPlayerReff.current;
    const updateProgress = () => {
      const currentTime = audio.currentTime;
      const duration = audio.duration;
      setTrackProgress((currentTime / duration) * 100);
      setCurrentTime(currentTime);
      if (currentTime === duration) {
        setIsPlaying(false)
      }
    };

    if (audio) {
      audio.addEventListener("timeupdate", updateProgress);
      audio.addEventListener("loadedmetadata", () => {
        setDuration(audio.duration);
      });
    }
    return () => {
      if (audio) {
        audio.removeEventListener("timeupdate", updateProgress);
        audio.removeEventListener("loadedmetadata", () => {
          setDuration(audio.duration);
        });
      }
    };
  }, [BlobURL]);

  useEffect(() => {
    setTrackProgress(0);
  }, [props.ButtonPressed]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    let seconds = Math.floor(timeInSeconds - minutes * 60);

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  };
  return (
    <div       
    className={`MaudioPlayer ${
      !props.cssAudioPlayer ? "MaudioPlayerNone" : ""
    }`}>
      <div className="MaudioPlayer-imageContainer">
        <img
          className="MaudioPlayer-image"
          src={props.presetImg || placeholderImg}
          alt="circle placeholder"
        />
      </div>
      <div className="MaudioPlayer-wrapper">
        <button
          type="button"

          className={"MaudioPlayer-playPauseButton"}
          onClick={handlePlayPause}
        >
          <img
            src={isPlaying ? pauseImage : playImage}
            alt={isPlaying ? "Pause" : "Play"}
            className={"MaudioPlayer-playPauseButtonImg"}
          />

        </button>
        <div className="MAudioPlayer-ProgressContainer">
          <audio className="audioPlayer-audio" ref={audioPlayerReff}
          ></audio>

          <input
            className="MaudioPlayer-progressBar"
            step="1"
            min="0"
            max="100"
            type="range"


            value={trackProgress}
            onChange={handleScrub}
            onMouseUp={handleScrub}
            onKeyUp={handleScrub}

          />

        {windowWidth > 465 && (
          <p className="MaudioPlayer-voice">
            {formatTime(currentTime)} / {formatTime(duration)}
          </p>
        )}
      </div>

      <div className="MaudioPlayer-volumeBarContainer">
        <img className="MaudioPlayer-volumeBarIMG" src={VolMin} />
        <input
          type="range"
          value={volume}
          step="0.01"
          min="0"
          max="1"
          className="MaudioPlayer-volumeBar"
          onChange={handleVolumeChange}
        />
        <img className="MaudioPlayer-volumeBarIMG" src={VolMax} />

      </div>
      <button
        type="button"
        className="voicelab-submitDownload">
        <img src={download} onClick={props.download_audio} className="voicelab-submitDownloadImg" />
      </button>
    </div >
    </div>
  );
}
export default MobileAudioPlayer;
