const createEventBody = async (pbid) => {
  return {
    type: "celeb.account.reset.to.free",
    pocketbaseID: pbid,
  };
};
async function resetToFreeCall(pbid) {
  try {
    const eventBody = await createEventBody(pbid);
    const response = await fetch("https://celeb-voice.com/stripe/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(eventBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    console.log("Free Tier Account Created!");
  } catch (error) {
    console.error("Error:", error);
  }
  console.log("sent delete request");
}

export default resetToFreeCall;
