import React, { useState, useEffect } from "react";
import "../css/home.css";
import "../css/signupin.css";
import "../css/privacy.css";
import LogoObject from "images/logoObject.png";
import { Link } from "react-router-dom";
import Footer from "hooks/Footer";

export default function Privacy() {
  return (
    <div className="mainWrapper">
      <Link to="/" className="signInOutTopbar">
        <img src={LogoObject} className="signInOutTopbarLogo"></img>
      </Link>
      <div className="text">
        <h2> Personal identification information:</h2>
        <p>
          We may collect personal identification information from Users in the
          following ways: When Users register or log in through the login and
          sign-up menu on our website. The personal identification information
          we collect is used solely for the purpose of authenticating users and
          ensuring the security of their accounts.
        </p>
        <h2> Storage and Security:</h2>
        <p>
          We store all personal identification information collected from Users
          on Oracle servers, ensuring appropriate security measures to protect
          against unauthorized access, alteration, disclosure, or destruction of
          personal information. Use of Cookies: Celeb-Voice uses cookies to
          enhance User experience and provide personalized features. These
          cookies allow us to auto-log in Users who have previously logged in,
          providing convenience during subsequent visits. Users have the option
          to disable cookies through their browser settings.
        </p>
        <h2> Third-Party Services:</h2>
        <p>
          Celeb-Voice utilizes Google AdSense for advertising purposes and
          Cloudflare for security and performance optimization. These
          third-party services may collect anonymous information about Users'
          interactions with the Site. Please refer to the respective privacy
          policies of these services for more details.
        </p>
        <h2>Children's Privacy:</h2>
        <p>
          Celeb-Voice is intended for adult users and does not knowingly collect
          personal information from individuals under the age of 18. If you
          believe that we have inadvertently collected personal information from
          a minor, please contact us immediately. Contact Us: If you have any
          questions or concerns regarding this Privacy Policy or the practices
          of Celeb-Voice.
        </p>
      </div>
      <Footer />
    </div>
  );
}
