import 'css/stripe.css'
import lockJPG from 'images/cart/lock.png'

const SubmitFormButton = ({ isFormValid }) => {
  return (
    <button className='border-none hover:cursor-pointer'
      type="submit"
      style={{
        backgroundColor: "#380ca4",
        borderRadius: "6px",
        boxShadow:
          "box-shadow rgba(50, 50, 93, 0.1) 0px 0px 0px 1px inset, rgba(50, 50, 93, 0.1) 0px 2px 5px 0px, rgba(0, 0, 0, 0.07) 0px 1px 1px 0px",
        height: "44px",
        position: "relative",
      }}
    >
      <p
        style={{
          margin:'0',
          color: "rgb(255, 255, 255)",
          fontSize: "16px",
          fontFamily: "segoeui-semibold",
          fontWeight: "500",
          opacity: isFormValid ? "1.0" : "0.6",
        }}
      >
        Pay
      </p>
      {isFormValid && (
        <div className="absolute top-0 right-0 flex justify-center items-center h-full">
          <img className="h-7 mr-2.5" src={lockJPG} />
        </div>
      )}
    </button>
  );
};

export default SubmitFormButton;
