import pb from "UIM";
import { useState, useEffect, useCallback } from "react";

export default function useGetVoiceData() {

    const [VoiceArray, setVoiceArray] = useState(null);

    const fetchData = useCallback(async () => {
        try {
            const records = await pb.collection("voice_files").getList(1, 50, {
                filter: `userID="${pb.authStore.model.id}"`,
            });

            if (records) {
                let array = []
                for (let i = 0; i < records.items.length; i++) {
                    let obj = {}
                    const record = records.items[i]
                    const voice_name = record['voice_name']
                    const record_id = record['id']

                    const PictureFilename = record['image'];
                    let PictureUrl;

                    if (PictureFilename) {
                        PictureUrl = pb.files.getUrl(record, PictureFilename, { 'thumb': '100x250' });
                    } else {
                        PictureUrl = null
                    }

                    obj["Name"] = voice_name
                    obj["Picture"] = PictureUrl
                    obj["Id"] = record_id

                    array.push(obj)
                }
                const stringArray = JSON.stringify(array)
                setVoiceArray(stringArray)
            }
        } catch (e) {

        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { VoiceArray, refetch: fetchData };
}
