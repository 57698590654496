import React, { useState, useEffect } from "react";
import "../css/home.css";
import "../css/password.css";
import "../css/signupin.css";
import { set, useForm } from "react-hook-form";

import pb from "UIM";

export default function LogOutPage() {
  pb.authStore.clear();
  window.location.href = "/";
  return <div className="mainWrapper">Logged Out</div>;
}
