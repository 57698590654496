import Tier1Image from 'images/stripe_images/tier1_image.png'
import Tier2Image from 'images/stripe_images/tier2_image.png'
import Tier3Image from 'images/stripe_images/tier3_image.png'

export default function getSubscriptionDetailsWithParams(param){
    try{
        const productMap = {
            'tier1-subscription-monthly': {title: 'Tier 1 Monthly Subscription', price: '$2.99', desc: 'Unlock 10,000 characters, all presets, and access to all free tier features, providing ample creative freedom at an affordable price.', image:Tier1Image},
            'tier2-subscription-monthly': {title: 'Tier 2 Monthly Subscription', price: '$9.99', desc: 'Engage in content creation with 30,000 characters, access to the dynamic Celeb-Mix Editor for interactive conversations, and the ability to create your own voices with the brand new Voice Lab!', image:Tier2Image},
            'tier1-subscription-yearly' : {title: 'Tier 1 Yearly Subscription', price: '$32.89', desc: 'Unlock 10,000 characters, all presets, and enjoy an ad-supported experience, providing ample creative freedom at an affordable price.', image:Tier1Image},
            'tier2-subscription-yearly' : {title: 'Tier 2 (PRO) Yearly Subscription', price: '$109.99', desc: 'Engage in content creation with 30,000 characters, access to the dynamic Celeb-Mix Editor for interactive conversations, and the ability to create your own voices with the brand new Voice Lab!', image:Tier2Image},
            'tier3-subscription-monthly' : {title: 'Tier 3 Monthly Subscription', price: '$45.00', desc: 'Elevate your creations with 100K characters, access to the revolutionary Celeb-Mix Editor, pushing the boundaries of storytelling and engagement, and the ability to create your own voices with the brand new Voice Lab!', image:Tier3Image},
            'tier3-subscription-yearly' : {title: 'Tier 3 Yearly Subscription', price: '$480.00', desc: 'Elevate your creations with 100K characters, access to the revolutionary Celeb-Mix Editor, pushing the boundaries of storytelling and engagement, and the ability to create your own voices with the brand new Voice Lab!', image:Tier3Image},
        }
        return productMap[param]
    }catch(e){
        return false
    }
    
}