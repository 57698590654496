import pb from "UIM";
import { useEffect, useState } from "react";

export default function useGetUserInfo() {
  /////////////////
  const [totalCharCount, setTotalCharCount] = useState("250");
  const [characterResetTime, setCharacterResetTime] = useState(
    "Not Subscribed to a Plan!"
  );
  const [accountTier, setAccountTier] = useState("");

  function daysUntilReset(firstDate) {
    let expires = new Date();
    expires.setDate(firstDate.getDate() + 30);

    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate());

    const daysLeft = (expires - currentDate) / (1000 * 60 * 60 * 24);
    return Math.ceil(daysLeft);
  }

  function getTotalChars(tier) {
    const chars = {
      0: "250",
      1: "10,000",
      2: "30,000",
      3: "100,000",
      4: "300,000",
    };

    setTotalCharCount(chars[tier]);
  }
  useEffect(() => {
    async function getAccountTier() {
      try {
        const records = await pb.collection("accountTiers").getList(1, 50, {
          filter: `userID="${pb.authStore.model.id}"`,
        });

        const recordItem = records.items[0];
        const Tier = recordItem["tier"];

        getTotalChars(Tier);
        const tiers = {
          0: "Free",
          1: "Tier 1",
          2: "Tier 2",
          3: "Tier 3",
          4: "Tier 4",
          5: "Tier 5",
        };
        setAccountTier(tiers[Tier]);
      } catch (e) {
        setAccountTier("Not Subscribed to a Plan!");
      }
    }
    async function getDaysLeft() {
      try {
        const records = await pb.collection("accountTiers").getList(1, 2, {
          filter: `userID="${pb.authStore.model.id}"`,
        });
        const recordItem = records.items[0];
        const updated_date = recordItem["updated"];

        const date = new Date(updated_date);
        const days_left = `${daysUntilReset(date)} days`;
        setCharacterResetTime(days_left);
      } catch (e) {
        console.log(e);
      }
    }
    /////////////////////
    getAccountTier();
    getDaysLeft();
  }, []);
  return { totalCharCount, characterResetTime, accountTier };
}
