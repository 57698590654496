import React, { useState } from "react";
import "../../css/PresetMenu.css";
import "../../css/presetOptions.css";
import donald from "../../images/donald.webp";
import biden from "../../images/biden.webp";
import obama from "../../images/obama.webp";
import benShapiro from "../../images/benShapiro.webp";
import ye from "../../images/ye.webp";
import andrewTate from "../../images/andrewTate.webp";
import elonMusk from "../../images/elonMusk.webp";
import joeRogan from "../../images/joeRogan.webp";
import queen from "../../images/queen.webp";
import johnnyDepp from "../../images/johnnyDepp.webp";
import neilDegrasse from "../../images/neilDegrasse.webp";
import billGates from "../../images/billGates.webp";
import iceSpice from "../../images/iceSpice.webp";
import morganFreeman from "../../images/morganFreeman.webp";
import steveJobs from "../../images/steveJobs.webp";
import aoc from "../../images/aoc.webp";
import adinRoss from "../../images/adinRoss.webp";
import hasan from "../../images/hasan.webp";
import xqc from "../../images/xqc.webp";
import davidGoggins from "../../images/davidGoggins.webp";
import lock from "../../images/lock.webp";
import { Link } from "react-router-dom";
//
import x from "../../images/xmark.webp";
export default function PresetMenu({
  handleSelect,
  className,
  handleButtonClick,
}) {
  const [typing, setTyping] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [activeOption, setActiveOption] = useState(null);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [containerVisible, setContainerVisible] = useState(true);

  const handleClick = (option, presetImg, color) => {
    handleSelect(option, presetImg, color);
    setActiveOption(option);
    setContainerVisible(false); // Hide the container after clicking
    handleButtonClick();
  };

  const handleClose = () => {
    setContainerVisible(false);
    handleButtonClick();
  };

  return (
    <div
      className={`PresetMenu-container ${className}`} // Combine the classes here
      onClick={(e) => e.stopPropagation()}
      style={{ display: containerVisible ? "block" : "none" }} // Apply the display style conditionally
    >
      <button
        onClick={handleClose}
        type="button"
        className="PresetMenu-closeMenu"
      >
        <img src={x} style={{ maxHeight: "100%", maxWidth: "100%" }} />
      </button>
      <div className="PresetMenu">






















        {/*active-voices*/}
        {/*
        trump

        <div className="PresetMenu-PresetOptions" onClick={() => handleClick("donald", donald, "#d82129")}>
          <img
            src={donald}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetMenu-text">Donald Trump</div>
        </div>
        
        */}

        {/*
        biden
        <div className="PresetMenu-PresetOptions" onClick={() => handleClick("biden", biden, "#d82129")}>
          <img
            src={biden}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetMenu-text">Joe Biden</div>
        </div>
        
        */}

        <div className="PresetMenu-PresetOptions" onClick={() => handleClick("davidGoggins", davidGoggins, "#d82129")}>
          <img
            src={davidGoggins}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetMenu-text">David Goggins</div>
        </div>



        <div className="PresetMenu-PresetOptions" onClick={() => handleClick("elonMusk", elonMusk, "#d82129")}>
          <img
            src={elonMusk}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetMenu-text">Elon Musk</div>
        </div>


        <div className="PresetMenu-PresetOptions" onClick={() => handleClick("ye", ye, "#d82129")}>
          <img
            src={ye}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetMenu-text">ye</div>
        </div>


        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={adinRoss}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Adin Ross</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>




        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={andrewTate}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Andrew Tate</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>



        {/*
        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={obama}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Obama</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>
        */}



        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={iceSpice}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Ice Spice</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>



        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={billGates}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Bill Gates</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>



        {/*
        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={elonMusk}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Elon Musk</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>
        */}



        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={morganFreeman}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Morgan Freeman</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>



        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={steveJobs}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Steve Jobs</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>



        {/*
        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={ye}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Ye</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>
        */}



        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={aoc}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">AOC</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>



        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={neilDegrasse}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Neil Degrasse</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>



        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={hasan}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Hasan</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>



        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={benShapiro}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Ben Shapiro</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>



        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={xqc}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">XQC</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>



        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={queen}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Queen Elizabeth</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>



        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={joeRogan}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Joe Rogan</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>



        <Link className="PresetMenu-PresetOptions" to="/pricing">
          <img
            src={johnnyDepp}
            alt="Avatar"
            className={`PresetOptions-imgLocked ${activeOption === "trump" ? "PresetOptions-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Johnny Depp</div>
          <img src={lock} alt="Lock" className="PresetMenu-imgLock" />
        </Link>




        {/*end-of-voices*/}






















      </div>
    </div>
  );
}

