//AudioPlayer.js
import React, { useState, useRef, useEffect, forwardRef } from "react";
import "../css/AudioPlayer.css";
import playImage from "../images/Play.webp";
import pauseImage from "../images/Pause.webp";
import placeholderImg from "../images/logoObject.png";
import VolMax from '../images/VolMax.webp'
import VolMin from '../images/VolLow.webp'
import { Oval } from "react-loader-spinner";

function AudioPlayer(props) {
  //const placeholderImg = props.placeholderImg; // image for audio
  const audioPlayerReff = props.refrence; // audio ref object  // audio ref object
  const [isPlaying, setIsPlaying] = useState(false); // use state for if the Audio player is playing or not
  const [trackProgress, setTrackProgress] = useState(0); // progress bar for Audio player
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1.0);
  //const [BlobURL, setBlobURL] = useState(props.blobURL);
  let BlobURL = props.blobURL
  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    audioPlayerReff.current.volume = newVolume;
    setVolume(newVolume);
  };
  const handlePlayPause = () => {
    console.log("press")
    // Check if audioPlayerReff and audioPlayerReff.current are defined
    if (audioPlayerReff && audioPlayerReff.current) {
      if (isPlaying) {
        audioPlayerReff.current.pause();
      } else {

        audioPlayerReff.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const handleScrub = (e) => {
    //function gets called when audio scrubber is
    const newCurrentTime =
      (e.target.value / 100) * audioPlayerReff.current.duration;
    audioPlayerReff.current.currentTime = newCurrentTime;
    setTrackProgress(e.target.value);
  };

  useEffect(() => {
    if (BlobURL) {
      console.log("cool animation play")// play cool animation here
    }

    setTrackProgress(0);
    const audio = audioPlayerReff.current;
    const updateProgress = () => {
      const currentTime = audio.currentTime;
      const duration = audio.duration;
      setTrackProgress((currentTime / duration) * 100);
      setCurrentTime(currentTime);
      if (currentTime === duration) {
        setIsPlaying(false)
      }
    };

    if (audio) {
      audio.addEventListener("timeupdate", updateProgress);
      audio.addEventListener("loadedmetadata", () => {
        setDuration(audio.duration);
      });
    }
    return () => {
      if (audio) {
        audio.removeEventListener("timeupdate", updateProgress);
        audio.removeEventListener("loadedmetadata", () => {
          setDuration(audio.duration);
        });
      }
    };
  }, [BlobURL]);

  useEffect(() => {
    setTrackProgress(0);
  }, [props.ButtonPressed]);

  // Helper function to format a time in seconds into minutes and seconds
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    let seconds = Math.floor(timeInSeconds - minutes * 60);

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  };

  return (
    <div className="audioPlayer">
      <div className="audioPlayer-imageContainer">

          <img
            className="audioPlayer-image"
            src={props.presetImg || placeholderImg}
            alt="circle placeholder"
          />
      </div>


      <audio className="audioPlayer-audio" ref={audioPlayerReff}></audio>
      <p className="audioPlayer-voice">
        {formatTime(currentTime)} / {formatTime(duration)}
      </p>
      <input
        disabled={!props.ButtonPressed}
        type="range"
        value={trackProgress}
        step="1"
        min="0"
        max="100"
        className="audioPlayer-progressBar"
        onChange={handleScrub}
        onMouseUp={handleScrub}
        onKeyUp={handleScrub}
      />

      <button
        type="button"
        disabled={!props.ButtonPressed}
        className={props.ButtonPressed ? "audioPlayer-playPauseButton" : "audioPlayer-PauseButtonGray"}
        onClick={handlePlayPause}
      >
        <img
          src={isPlaying ? pauseImage : playImage}
          alt={isPlaying ? "Pause" : "Play"}
        />

      </button>
      <div className="audioPlayer-volumeBarContainer">
        <img className="audioPlayer-volumeBarIMG" src={VolMin} />
        <input
          type="range"
          value={volume}
          step="0.01"
          min="0"
          max="1"
          className="audioPlayer-volumeBar"
          onChange={handleVolumeChange}
        />
        <img className="audioPlayer-volumeBarIMG" src={VolMax} />
      </div>
    </div>
  );
}
export default AudioPlayer;
