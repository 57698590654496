import React, { useState, useEffect } from "react";
import "../../css/home.css";
import "../../css/password.css";
import "../../css/signupin.css";
import { set, useForm } from "react-hook-form";
import LogoObject from "images/logoObject.png";
import { Link } from "react-router-dom";
import pb from "UIM";
import deleteAccountCall from "js/deleteAccount";

export default function DeletePart2() {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(
    "There was a problem deleting your account"
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 0);
  }, []);

  const DeleteAccDataSubmit = async (DeleteAccData) => {
    setLoading(false);
    setError(false);
    const email = DeleteAccData.email;
    const deleteField = DeleteAccData.password;
    const deleteFieldCorrect = "DELETE";
    if (deleteField === deleteFieldCorrect) {
      //try to view the email if we cant view it, it is not theirs
      try {
        const record = await pb
          .collection("users")
          .getOne(pb.authStore.model.id);
        if (record["email"] !== email) {
          setError(true);
          setErrorText("That is not the email associated with your account");
        } else {
          setLoading(true);
          //try to authenticate with the creds they provided, if we cant then they gave us the wrong password info

          try {
            await deleteAccountCall(pb.authStore.model.id); // evil code
            pb.authStore.clear();
            setLoading(false);
            window.location.href = "./";
          } catch (e) {
            console.log(e);
            setError(true);
            setLoading(false);
            setErrorText("There was an error deleting your account");
          }
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      setErrorText("Please type the correct phrase!");
      setError(true);
    }
  };
  return (
    <div className="mainWrapper">
      <Link to="/" className="signInOutTopbar">
        <img src={LogoObject} className="signInOutTopbarLogo"></img>
      </Link>
      <div className={`signInOutForm${loaded ? " loaded" : ""}`}>
        <form
          className={`signForm-container${loaded ? " loaded" : ""}`}
          onSubmit={handleSubmit(DeleteAccDataSubmit)}
        >
          <p className="signForm-text" style={{ textAlign: "center" }}>
            Enter Credentials
          </p>
          <p
            style={{
              textAlign: "center",
              color: "white",
              fontFamily: "Metropolis-Medium",
            }}
          >
            Please enter the email associated with your account
          </p>
          <input
            type="text"
            placeholder={pb.authStore.model.email}
            className="signForm-textInput"
            {...register("email")}
          ></input>
          <p
            style={{
              textAlign: "center",
              color: "white",
              fontFamily: "Metropolis-Medium",
            }}
          >
            To proceed, type "DELETE" in the input field below.
          </p>
          <input
            type="text"
            placeholder="DELETE"
            className="signForm-textInput"
            {...register("password")}
          ></input>

          <button type="submit" className="signForm-buttonLogin">
            {isLoading ? "Loading..." : "Delete Account"}
          </button>
          <p
            className="signForm-failureText"
            style={{ display: error ? "block" : "none" }}
          >
            {errorText}
          </p>
        </form>
      </div>
    </div>
  );
}
