//PresetMenuEditor.js
import React, { useState } from "react";
import "../../css/PresetMenu.css";
import "../../css/presetOptions.css";
import donald from "../../images/donald.webp";
import biden from "../../images/biden.webp";
import obama from "../../images/obama.webp";
import benShapiro from "../../images/benShapiro.webp";
import ye from "../../images/ye.webp";
import andrewTate from "../../images/andrewTate.webp";
import elonMusk from "../../images/elonMusk.webp";
import joeRogan from "../../images/joeRogan.webp";
import queen from "../../images/queen.webp";
import johnnyDepp from "../../images/johnnyDepp.webp";
import neilDegrasse from "../../images/neilDegrasse.webp";
import billGates from "../../images/billGates.webp";
import iceSpice from "../../images/iceSpice.webp";
import morganFreeman from "../../images/morganFreeman.webp";
import steveJobs from "../../images/steveJobs.webp";
import aoc from "../../images/aoc.webp";
import adinRoss from "../../images/adinRoss.webp";
import hasan from "../../images/hasan.webp";
import xqc from "../../images/xqc.webp";
import davidGoggins from "../../images/davidGoggins.webp";
import x from "../../images/xmark.webp";

export default function PresetMenu({ handleSelect, closeMenu, className }) {
  const [typing, setTyping] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [activeOption, setActiveOption] = useState(null);
  const [overlayVisible, setOverlayVisible] = useState(false);

  const handleClick = (option, presetImg, color) => {
    handleSelect(option, presetImg, color);
    setActiveOption(option);
    closeMenu();
  };

  return (
    <div
      className={`PresetMenu-container ${className}`} // Combine the classes here
      onClick={(e) => e.stopPropagation()}
    >
      <button
        onClick={() => closeMenu()}
        type="button"
        className="PresetMenu-closeMenu"
      >
        <img src={x} style={{ maxHeight: "100%", maxWidth: "100%" }} />
      </button>
      {/* copy here*/}
      <div className="PresetMenu">

        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("davidGoggins", davidGoggins, "#d82129")}
        >
          <img
            src={davidGoggins}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetOptions-imgLock" : ""
              }`}
          />
          <div className="PresetOptions-text">David Goggins</div>
        </div>

        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("ye", ye, "#d82129")}
        >
          <img
            src={ye}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Kanye West</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("benShapiro", benShapiro, "#d82129")}
        >
          <img
            src={benShapiro}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Ben Shapiro</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("elonMusk", elonMusk, "#d82129")}
        >
          <img
            src={elonMusk}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Elon Musk</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("joeRogan", joeRogan, "#d82129")}
        >
          <img
            src={joeRogan}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Joe Rogan</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("queen", queen, "#d82129")}
        >
          <img
            src={queen}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Queen Elizabeth</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("johnnyDepp", johnnyDepp, "#d82129")}
        >
          <img
            src={johnnyDepp}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Johnny Depp</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("neilDegrasse", neilDegrasse, "#d82129")}
        >
          <img
            src={neilDegrasse}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Neil Degrasse</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("billGates", billGates, "#d82129")}
        >
          <img
            src={billGates}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Bill Gates</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("iceSpice", iceSpice, "#d82129")}
        >
          <img
            src={iceSpice}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Ice Spice</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("morganFreeman", morganFreeman, "#d82129")}
        >
          <img
            src={morganFreeman}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Morgan Freeman</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("steveJobs", steveJobs, "#d82129")}
        >
          <img
            src={steveJobs}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Steve Jobs</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("aoc", aoc, "#d82129")}
        >
          <img
            src={aoc}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Ocasio-Cortez</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("adinRoss", adinRoss, "#d82129")}
        >
          <img
            src={adinRoss}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Adin Ross</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("hasan", hasan, "#d82129")}
        >
          <img
            src={hasan}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">Hasan</div>
        </div>
        <div
          className="PresetMenu-PresetOptions"
          onClick={() => handleClick("xqc", xqc, "#d82129")}
        >
          <img
            src={xqc}
            alt="Avatar"
            className={`PresetMenu-img ${activeOption === "trump" ? "PresetMenu-imgActive" : ""
              }`}
          />
          <div className="PresetOptions-text">xQc</div>
        </div>

      </div>
      {/* copy here*/}
    </div>
  );
}
