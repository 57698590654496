import React, { useState, useEffect } from "react";
import "../../css/home.css";
import "../../css/password.css";
import "../../css/signupin.css";
import { set, useForm } from "react-hook-form";
import LogoObject from "images/logoObject.png";
import { Link } from "react-router-dom";
import pb from "UIM";
import cancelStripeSubscription from "./cancelSubscription.js";
import resetToFreeCall from "js/resetToFree.js";

export default function CancelSubscriptionPart2() {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(
    "There was a problem deleting your account"
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 0);
  }, []);

  const DeleteAccDataSubmit = async (DeleteAccData) => {
    setLoading(false);
    setError(false);

    try {
      setLoading(true);
      try {
        resetToFreeCall(pb.authStore.model.id);
        cancelStripeSubscription();
        ///////////////////  api call to cancel subscription

        setLoading(false);
        window.location.href = "./";
      } catch (e) {
        console.log(e);
        setError(true);
        setLoading(false);
        setErrorText("There was an error canceling your subscription");
      }
      setLoading(false);
    } catch (e) {
      //wrong passwrod info
      setLoading(false);
      setError(true);
      setErrorText("Password is incorrect");
    }



  };
  return (
    <div className="mainWrapper">
      <Link to="/" className="signInOutTopbar">
        <img src={LogoObject} className="signInOutTopbarLogo"></img>
      </Link>
      <div className={`signInOutForm${loaded ? " loaded" : ""}`}>
        <form
          className={`signForm-container${loaded ? " loaded" : ""}`}
          onSubmit={handleSubmit(DeleteAccDataSubmit)}
        >
          <p className="signForm-text" style={{ textAlign: "center" }}>
            Cancel Subscription
          </p>



          <button type="submit" className="signForm-buttonLogin">
            {isLoading ? "Loading..." : "Cancel Subscription"}
          </button>
          <p
            className="signForm-failureText"
            style={{ display: error ? "block" : "none" }}
          >
            {errorText}
          </p>
        </form>
      </div>
    </div>
  );
}
