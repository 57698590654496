import pb from "UIM";
import { useEffect, useState } from "react";

export default function useLimitPayment(compareT) {
  const [canClick, setCanClick] = useState("loading");

  useEffect(() => {
    async function checkAccountTier() {
      try {
        const records = await pb.collection("accountTiers").getList(1, 50, {
          filter: `userID="${pb.authStore.model.id}"`,
        });
        const recordItem = records.items[0];
        const Tier = recordItem["tier"];
        if (Tier < compareT) {
          setCanClick(true);
        } else {
          setCanClick(false);
        }
      } catch (e) {
        // if this executes it means there was no record connected to the account (The person never bought anything)
        return setCanClick(true);
      }
    }
    checkAccountTier();
  }, []);
  return { canClick };
}
