const createEventBody = async (pbid) => {
  return {
    type: "needs.free.account",
    pocketbaseID: pbid,
  };
};

async function makeTier0Account(pbid) {
  try {
    const eventBody = await createEventBody(pbid);
    const response = await fetch("https://celeb-voice.com/stripe/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(eventBody),
    }).then(() => {
      return 200
    });

  } catch (error) {
    console.error("Error:", error);
  }
}

export default makeTier0Account;
