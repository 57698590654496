import pb from "UIM";
import { useEffect, useState } from "react";

export default function useGetCharCount() {
  const [charCount, setCharCount] = useState("0");
  useEffect(() => {
    async function checkChar() {
      try {
        const records = await pb.collection("charCounts").getList(1, 2, {
          filter: `userID="${pb.authStore.model.id}"`,
        });
        const record = records.items[0];
        const charCount = record["charCount"];
        setCharCount(charCount);
      } catch (e) {
        console.log(e);
        console.log(
          "Possible that user has no 'charCount' record, they must select a plan"
        );
      }
    }
    if (pb.authStore.isValid) checkChar(); // only run this function if user is logged in
  }, []);

  return { charCount };
}
