import pb from "UIM";

export default async function decreaseCharCount(amount) {
  /* Constrainsts:
            This function assumes that the 'amount' parameter is always a number
            This function assumes that the user has a 'charCount' record, the only way
            that they would not have one, is if they didnt select a plan from the pricing page
    */
  const records = await pb.collection("charCounts").getList(1, 2, {
    filter: `userID="${pb.authStore.model.id}"`,
  });
  const record = records.items[0];
  const recordID = record["id"];
  const charCount = record["charCount"];
  let newCount;

  if (charCount - amount <= 0) {
    newCount = 0;
  } else {
    newCount = charCount - amount;
  }

  const data = {
    charCount: newCount,
  };
  const update = await pb.collection("charCounts").update(recordID, data);
  return newCount;
}
