//aieditor.jsx
import React, { useState, useEffect } from "react";
import "../css/home.css";
import "../css/signupin.css";
import "../css/AIEditor.css";
import download from "images/download.webp";
import placeholderImg from "../images/logoObject.png";
import Topbar from "hooks/Topbar.js";
import TopbarLI from "hooks/TopbarLI.js";
import AudioPlayer from "hooks/AudioPlayer";
import { useForm } from "react-hook-form";
import Footer from "hooks/Footer";
import pb from "UIM";
import SwiperEditor from "hooks/EditorSwiper.js";
import Tutorial from "hooks/tutorial.js";
import TimeLineToSpeech from "hooks/TimeLineToAudio";
import Slider from "rc-slider";
import { Oval } from "react-loader-spinner";
import useGetCharCount from "hooks/useGetCharCount";
import decreaseCharCount from "hooks/AccountHandling/CharDecrement";
import TutorialButton from "../images/tutorialbutton.webp";

async function canProcceed(charAmount) {
  try {
    const records = await pb.collection("charCounts").getList(1, 2, {
      filter: `userID="${pb.authStore.model.id}"`,
    });
    const record = records.items[0];
    const charCount = record["charCount"];

    if (charCount - charAmount < 0) {
      console.log("cannot proceed");
      return false;
    }
    console.log("can proceed");
    return true;
  } catch (e) {
    console.log(
      "Possible that user has no 'charCount' record, they must select a plan"
    );
    return false;
  }
}

export default function AIEditor() {
  const { charCount } = useGetCharCount();
  const [firstPageLoad, setFirstPageLoaded] = useState(true);
  const [liveCharCount, setLiveCharCount] = useState(0);
  if (charCount != 0 && firstPageLoad) {
    setLiveCharCount(charCount);
    setFirstPageLoaded(false);
  }
  const [typing, setTyping] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [currentClickedIndex, setCurrentClickedIndex] = useState(null);
  const [showTextArea, setShowTextArea] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [showSlider, setShowSlider] = useState(false);
  const [loading, setLoading] = useState(false);
  const [StabilitySliderValue, setStabilitySliderValue] = useState(80);
  const [SimilaritySliderValue, setSimilaritySliderValue] = useState(80);
  const [voiceGenerationError, setVoiceGenerationError] = useState(false);
  const [outOfCharacters, setOutOfCharacters] = useState(false);
  const [selectedVoiceError, setSelectedVoiceError] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);

  const toggleSliderDisplay = () => {
    setShowSlider((currentShow) => !currentShow);
  };

  const { handleTextToSpeech, audioPlayerRef, downloadAudio } =
    TimeLineToSpeech(StabilitySliderValue, SimilaritySliderValue);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const TopbarComponent = ({ isValid }) => {
    return isValid ? <TopbarLI /> : <Topbar />;
  };
  const handleFocus = () => {
    console.log("onFocus triggered");
    setTyping(true);
  };

  const handleBlur = () => {
    console.log("onBlur triggered");
    setTyping(false);
  };
  const handleApplyText = () => {
    setSelectedVoiceError(false);
    if (currentClickedIndex !== null) {
      setSelectedOptions((prevSelectedOptions) => {
        const newSelectedOptions = [...prevSelectedOptions];
        if (newSelectedOptions[currentClickedIndex]) {
          newSelectedOptions[currentClickedIndex].TextInput =
            document.querySelector(".Editor-inputText").value;
        } else {
          newSelectedOptions[currentClickedIndex] = {
            TextInput: document.querySelector(".Editor-inputText").value,
          };
        }
        return newSelectedOptions;
      });
      setShowSuccessMessage(true);
      setShowTextArea(false);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 1200);
    }
  };
  const handleElementClick = (index) => {
    console.log("Element clicked:", index);
    setCurrentClickedIndex(index);
    setShowTextArea(true); // Show the textarea when an element is clicked

    // Wait for the textarea to be rendered before trying to access it
    setTimeout(() => {
      const textArea = document.querySelector(".Editor-inputText");
      if (textArea) {
        textArea.value = selectedOptions[index]?.TextInput || "";
      } else {
        console.error("Textarea element not found");
      }
    }, 0);

    setSelectedImageIndex((prevIndex) => {
      if (prevIndex === index) {
        return -1;
      }
      return index;
    });
  };
  const getCombinedCount = (data) => {
    let c = 0;
    for (let i = 0; i < selectedOptions.length; i++) {
      const textin = data[i]["TextInput"];
      c += textin.length;
    }
    return c;
  };
  const onSubmitEditorData = (EditorData) => {
    if (!pb.authStore.isValid) {
      window.location.href = "/signUp";
    }
    setSelectedVoiceError(false);
    setLoading(true);
    setVoiceGenerationError(false);
    console.log("Loading...");

    if (selectedOptions.length > 0) {
      if (selectedOptions[0]["TextInput"] != null) {
        const charAmount = getCombinedCount(selectedOptions);

        const proc = canProcceed(charAmount).then((res) => {
          if (res) {
            try {
              handleTextToSpeech(selectedOptions)
                .then((res) => {
                  setLoading(false);
                  if (res === "failed") {
                    setVoiceGenerationError(true);
                  } else {
                    const newCount = decreaseCharCount(charAmount).then(
                      (count) => {
                        setLiveCharCount(count);
                        setButtonPressed(true);
                      }
                    );
                  }
                })
                .catch((error) => {
                  setLoading(false);
                  console.log("error generating voice");
                  setVoiceGenerationError(true);
                });
            } catch (e) {
              setLoading(false);
              //console.log("error generating voice");
              setVoiceGenerationError(true);
            }
          } else {
            console.log("Out of characters");
            setOutOfCharacters(true);
            setLoading(false);
          }
        });
      } else {
        console.log(selectedOptions.length);
        setLoading(false);
        setSelectedVoiceError(true);
      }
    } else {
      console.log(selectedOptions.length);
      setLoading(false);
      setSelectedVoiceError(true);
    }
    /////////////////////////////////////////

    //error occured while generating speech

    //setLiveCharCount();
  };
  useEffect(() => {
    if (loading && audioPlayerRef.current && audioPlayerRef.current.src) {
      setLoading("hide");
      console.log("Done");
    }
  }, [loading, audioPlayerRef.current?.src]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".Editor-inputText") &&
        !event.target.closest(".Editor-ApplyTextButton") &&
        !event.target.closest(".swiper-slide")
      ) {
        setShowTextArea(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenuVisibility = () => {
    console.log("Toggling menu visibility");
    setMenuVisible(!menuVisible);
  };
  return (
    <div className="mainWrapper">
      {menuVisible ? <Tutorial closeMenu={toggleMenuVisibility} /> : null}

      {loading ? (
        <div className="voiceLab-LoadingContainer">
          <Oval
            height={140}
            width={140}
            color="#8250ff"
            wrapperStyle={{}}
            ariaLabel="oval-loading"
            secondaryColor="#380ca4"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : null}
      <TopbarComponent isValid={pb.authStore.isValid} />
      <form
        className="Editor-mainFormContent"
        onSubmit={handleSubmit(onSubmitEditorData)}
      >
        <div className="Editor-topSection">
          <button
            className="Editor-TutorialButtonContainer"
            type="button"
            onClick={toggleMenuVisibility}
          >
            <img
              style={{ maxHeight: "100%", maxWidth: "100%" }}
              src={TutorialButton}
            />
            <p className="Googletext">Need Help?</p>
          </button>
          <div className="Editor-AudioPlayerContainer">
            <AudioPlayer
              presetImg={placeholderImg}
              refrence={audioPlayerRef}
              controls
              ButtonPressed={buttonPressed}
            ></AudioPlayer>
          </div>
          <p
            className={`voiceapp-NotEnoughCharAlert ${outOfCharacters ? "show" : ""
              }`}
          >
            Not Enough Characters Left
          </p>
          <p
            className={`voiceapp-NotEnoughCharAlert ${selectedVoiceError ? "show" : ""
              }`}
          >
            Error No Voices Configured
          </p>
          <p
            className={`voiceapp-NotEnoughCharAlert ${voiceGenerationError ? "show" : ""
              }`}
          >
            Error Generating Voice. Try Again
          </p>
          <div className="Editor-generateButtonContainer">
            <button
              className={`Editor-generateButton${selectedOptions.length === 0
                ? " Editor-generateButtonNoPreset"
                : ""
                }`}
              type="submit"
            >
              Generate
            </button>

            <button
              className="Editor-submitDownload"
              onClick={downloadAudio}
              type="button"
            >
              Download
              <img src={download} className="Editor-submitDownloadImg" />
            </button>
          </div>
          <button
            className="Editor-advancedOptions"
            type="button"
            onClick={toggleSliderDisplay}
          >
            Advanced Options
          </button>
          <div
            className="Editor-sliderContainer"
            style={{ display: showSlider ? "block" : "none" }}
          >
            <div className="slider-label">Stability</div>
            <Slider
              className="slider-editor"
              trackClassName="slider-track"
              handleClassName="slider-handle"
              defaultValue={50}
              min={0}
              max={100}
              step={5}
              onChange={(value) => setStabilitySliderValue(value)}
            />
            <div className="slider-label">Clarity</div>
            <Slider
              className="slider-editor"
              trackClassName="slider-track"
              handleClassName="slider-handle"
              defaultValue={50}
              min={0}
              max={100}
              step={5}
              onChange={(value) => setSimilaritySliderValue(value)}
            />
          </div>
        </div>
        <div className="Editor-bottomSection">
          {pb.authStore.isValid ? (
            <div className="Editor-charLimit">
              {liveCharCount} Characters Left
            </div>
          ) : null}
          <div className="Editor-timelineContainer">
            <SwiperEditor
              typing={typing}
              setSelectedOptions={setSelectedOptions}
              onElementClick={handleElementClick}
              selectedOptions={selectedOptions}
            />
          </div>
          <div className="Editor-textInputContainer">
            {showSuccessMessage && (
              <p className="Editor-textApplied">Text applied!</p>
            )}

            {showTextArea ? (
              <textarea
                className="Editor-inputText"
                placeholder="Unleash the voice of your favorite AI Celeb"
                {...register("textInput", { required: true })}
              />
            ) : null}

            <button
              className="Editor-ApplyTextButton"
              type="button"
              onClick={handleApplyText}
            >
              Apply Text
            </button>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
}
