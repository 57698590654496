import React, { useState, useEffect } from "react";
import "../css/SuccPurPage.css";
import pb from "UIM";
import TopbarLI from "hooks/TopbarLI.js";
import Footer from "hooks/Footer";
import X from "images/xmark.webp";

export default function SuccPurPage() {
  return (
    <div className="mainWrapper">
      <TopbarLI />
      <div className="succPurPage-wrapper">
        <h1 className="succPurPage-topttext">Please Try again!</h1>
        <div className="succPurPage-Centerbox">
          <img src={X} className="succPurPage-X" />
          <p className="Centerbox-text">
            Failure
            <p className="Centerbox-under">Your purchase has failed!</p>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
