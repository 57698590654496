import React, { useState, useEffect, useId } from "react";
import "../css/voicelab.css";
import pb from "UIM";
import TopbarLI from "hooks/TopbarLI.js";
import Topbar from "hooks/Topbar";
import Footer from "hooks/Footer";
import AudioPlayer from "hooks/AudioPlayer";
import VoiceLabTemplate from "hooks/voicelab/voiceLabTemplate";
import Plus from "images/Plus.webp";
import VoiceInfo from "hooks/voicelab/VoiceInfo";
import RemoveVoice from "hooks/voicelab/removeVoiceWindow";
import useGetVoiceData from "hooks/voicelab/getvoicedata";
import useGetUserInfo from "hooks/useUserInfo";
import { useNavigate } from "react-router-dom";
import useRemainingVoiceSlots from "hooks/voicelab/availableVoices.js";
import MobileAudioPlayer from "hooks/MobileAudioPlayer";
import placeholderImg from "../images/logoObject.png";
import { set } from "react-ga";
import { useForm } from "react-hook-form";
import useVoiceLabTextToSpeech from "hooks/voicelab/js/UseTextToSpeechLab.js";
import { Oval } from "react-loader-spinner";
import Xmark from "images/xmark.webp";

export default function VoiceLab() {
  let navigate = useNavigate();

  const [voiceInfoVisible, setVoiceInfoVisible] = useState(true);

  const [removeButton, setRemoveButton] = useState(true);
  const [Voices, setVoices] = useState([]);
  const [CompletedFetch, setCompletedFetch] = useState(false);
  const { VoiceArray, refetch } = useGetVoiceData();
  const { accountTier } = useGetUserInfo();
  const [showRemoveComponent, setShowRemoveComponent] = useState(false);
  const [showUseComponent, setShowUseComponent] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedImage, setSelectedImage] = useState(placeholderImg);
  const [SelectedPocketBaseID, setSelectedPocketBaseID] = useState(null);
  const [loadingVoiceInfo, setLoadingVoiceInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [audioPlayerVisible, setAudioPlayerVisible] = useState(false);
  const [cssAudioPlayer, setCssAudioPlayer] = useState(false);
  const { handleTextToSpeechLab, downloadAudio, audioPlayerRef, blobURL } =
    useVoiceLabTextToSpeech();

  const { UsedSlots, setUsedSlots, TotalSlots, canMakeNewVoice, fetchData } =
    useRemainingVoiceSlots();

  useEffect(() => {
    if (VoiceArray) {
      setVoices(JSON.parse(VoiceArray));
      setCompletedFetch(true);
    }
  }, [VoiceArray]);

  useEffect(() => {
    fetchData();
  }, [VoiceArray, fetchData, UsedSlots]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const HandleRemoveClick = (SlotName) => {
    setShowRemoveComponent(true);
    //console.log(SlotName);
    setSelectedSlot(SlotName);
  };

  const HandleUseClick = (SlotImage, SlotName, SlotPocketBaseRecordID) => {
    setShowUseComponent(true);
    setSelectedSlot(SlotName);
    setSelectedImage(SlotImage);
    setSelectedPocketBaseID(SlotPocketBaseRecordID);
  };

  const handleRefetch = () => {
    fetchData();
  };

  const HandleRemoveVoice = async () => {
    // //remove voice from data base
    setRemoveButton(false);
    refetch();
    const record_id = selectedSlot[1];
    await pb.collection("voice_files").delete(record_id);
    refetch();
    setShowRemoveComponent(false);
    setRemoveButton(true);
  };

  const TopbarComponent = ({ isValid }) => {
    return isValid ? <TopbarLI /> : <Topbar />;
  };
  const handleRemoveComponentClose = () => {};

  const [DisableSubmitButton, setDisableSubmitButton] = useState(false);

  const SubmitGenerateData = async (textValue) => {
    // function for handling voice generation
    setIsLoading(true);
    setDisableSubmitButton(true);

    setAudioPlayerVisible(true);
    try {
      await handleTextToSpeechLab(textValue["textInput"], SelectedPocketBaseID);
    } catch (e) {
      alert("An error has occured. Please try again in a moment.");
      setAudioPlayerVisible(false);
      setIsLoading(false);
      setDisableSubmitButton(false);
      setShowUseComponent(false);
      return;
    }
    setIsLoading(false);
    setCssAudioPlayer(true);
    setDisableSubmitButton(false);
    setShowUseComponent(false);
  };
  /////////////////////////////////////////////////////////////////////////////
  const UseComponent = ({ setShowUseComponent }) => {
    const handleUseComponentClose = () => {
      setShowUseComponent(false);
      setSelectedSlot(null);
      setSelectedImage(null);
      setSelectedPocketBaseID(null);
    };

    return (
      <div className="voicelab-UseWrapper">
        <form
          onSubmit={handleSubmit(SubmitGenerateData)}
          className="UseComponent-Form"
        >
          <button
            onClick={handleUseComponentClose}
            className="UseComponent-imgContainer"
          >
            <img src={Xmark} className="UseComponent-imgClose"></img>
          </button>
          <div className="UseComponent-labelContainer">
            <img className="UseComponent-img" src={selectedImage}></img>
            <label className="UseComponent-label">{selectedSlot}</label>
          </div>
          <div className="UseComponent-textareaContainer">
            <textarea
              className="UseComponent-textarea"
              {...register("textInput", { required: true })}
            ></textarea>
            <button
              disabled={DisableSubmitButton}
              className="UseComponent-Submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  };

  const RemoveComponent = ({ setShowRemoveComponent }) => {
    const handleRemoveComponentClose = () => {
      setShowRemoveComponent(false);
    };
    return (
      <div className={`voicelab-VoiceInfoWrapper`}>
        <div className="remove-wrapper">
          <div className="remove-top">
            <p className="remove-top-h1">Remove Voice</p>
            <p className="remove-top-h2">
              Are you sure you want to remove {selectedSlot[0]}?
            </p>
          </div>
          <div className="remove-bot">
            <label
              className="remove-cancel"
              onClick={handleRemoveComponentClose}
            >
              Cancel
            </label>
            <label className="remove-removeVoice" onClick={HandleRemoveVoice}>
              Remove Voice
            </label>
          </div>
        </div>
      </div>
    );
  };

  const HandleLoadingVoiceInfo = (value) => {
    setLoadingVoiceInfo(value);
  };

  useEffect(() => {
    console.log("loadingVoiceInfo changed:", loadingVoiceInfo);
  }, [loadingVoiceInfo]);

  /////////////////////////////////////////////////////////////////////////////

  return (
    <div className="mainWrapper">
      {audioPlayerVisible ? (
        <MobileAudioPlayer
          download_audio={downloadAudio}
          presetImg={selectedImage}
          refrence={audioPlayerRef}
          blobURL={blobURL}
          placeholderImg={placeholderImg}
          onCloseClickAudioPlayer={() => {
            setAudioPlayerVisible(false);
            setCssAudioPlayer(false);
          }}
          cssAudioPlayer={cssAudioPlayer}
        />
      ) : null}
      {isLoading ? (
        <div className="voiceLab-LoadingContainer">
          <Oval
            height={140}
            width={140}
            color="#8250ff"
            wrapperStyle={{}}
            ariaLabel="oval-loading"
            secondaryColor="#380ca4"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : null}
      <div
        className={`voicelab-clickOffNeg ${
          voiceInfoVisible ? "voicelab-clickOffNeg" : "voicelab-clickOffPos"
        }`}
        onClick={loadingVoiceInfo ? undefined : () => setVoiceInfoVisible(true)}
      />
      <div
        className={`voicelab-clickOffNeg ${
          !showRemoveComponent ? "voicelab-clickOffNeg" : "voicelab-clickOffPos"
        }`}
        onClick={() => setShowRemoveComponent(false)}
      />
      <TopbarComponent isValid={pb.authStore.isValid} />
      <VoiceInfo
        InfoClose={() => setVoiceInfoVisible(!voiceInfoVisible)}
        className={`voicelab-VoiceInfoWrapper ${
          voiceInfoVisible ? "voicelab-VoiceInfoWrapperNone" : ""
        }`}
        refetchFunction={refetch}
        updateState={fetchData}
        onLoadingChange={HandleLoadingVoiceInfo}
      />
      {showRemoveComponent && (
        <RemoveComponent setShowRemoveComponent={setShowRemoveComponent} />
      )}
      {showUseComponent && (
        <UseComponent setShowUseComponent={setShowUseComponent} />
      )}
      <div className="voicelab-wrapper">
        <div className="voicelab-container">
          <div
            className="voicelab-create"
            onClick={() => {
              // check if user is eligible to create voices
              if (!pb.authStore.isValid) {
                navigate("/signup");
              } else {
                let res = canMakeNewVoice();
                if (res === false) {
                  // if they arent eligible
                  navigate("/pricing"); //navigate them to the pricing page
                } else if (res === "waiting") {
                  //if the response is still loading
                  console.log("waiting...");
                } else if (res === true) {
                  // if they are eligible

                  if (accountTier === "Tier 2" || accountTier === "Tier 3") {
                    // if they are tier 2 or 3
                    setVoiceInfoVisible(!voiceInfoVisible); // allow them to open voice menu
                  } else {
                    // if they are tier 1 or Free tier
                    navigate("/pricing"); // redirect them to pricing
                  }
                }
              }
            }}
          >
            <img className="voicelab-createIMG" src={Plus}></img>
            <label className="voicelab-createText">Add a voice</label>
            <label className="voicelab-createAmt">
              {UsedSlots}/{TotalSlots}
            </label>
          </div>
          {CompletedFetch
            ? Voices.map((data) => (
                <VoiceLabTemplate
                  HandleRemoveClick={HandleRemoveClick}
                  HandleUseClick={HandleUseClick}
                  key={data.Id}
                  name={data.Name}
                  record_id={data.Id}
                  image={data.Picture}
                />
              ))
            : null}
        </div>
        {/*         
        <div className="voicelab-audioPlayerContainer">
          <div className="voicelab-mockAudioPlayer"></div>
        </div>
        */}
      </div>
      <Footer />
    </div>
  );
}
