import { useRef } from "react";
import { useState } from "react";
import pb from "UIM";
import axios from 'axios';

export default function useVoiceLabTextToSpeech() {
    const apiKey = process.env.REACT_APP_DAVE_ELEVEN_LABS_API_KEY;
    const stability = 0.80;
    const similarity = 0.80;

    const [blobURL, setBlobURL] = useState();
    const audioPlayerRef = useRef();

    const downloadAudio = () => {
        const link = document.createElement("a");
        link.download = "celeb-voice-audio-download.mp3";
        link.href = blobURL;
        link.click();
        //URL.revokeObjectURL(blobURL);
        console.log("Audio Downloaded");
    };

    const getVoiceFiles = async (voicePocketbaseID) => {
        try {
            const record = await pb.collection('voice_files').getOne(voicePocketbaseID);// get the pb voice record
            let audio_array = [];// create empty array that will store the voice files

            for (let i = 0; i < record.audio_files.length; i++) {// loop through the records files
                const audio_file_name = record.audio_files[i];// collect the file name
                const AudioURL = pb.files.getUrl(record, audio_file_name);// get the audio URL for the file
                const fetchURLS = async () => {// function to convert pb audio url into file object
                    const res = await fetch(AudioURL)// fetch the audio url
                        .then(response => response.blob())// get the audio blob from the response
                        .then(blob => {
                            let file = new File([blob], `${audio_file_name}.mp3`, { type: 'audio/mpeg' });// create a new file object with the audio blob
                            audio_array.push(file)// push the file object into the array
                        });
                    return audio_array;// return the new array
                };
                await fetchURLS();
            }
            return ["success", audio_array];// return the completed array
        } catch (e) {
            return ["failed", 404];
        }
    };

    const createTempVoiceSlot = async (voiceName, audioFiles) => {
        try {
            const formData = new FormData();// create a new form for the request
            formData.append('name', voiceName);// use the pb voice record ID for the name field

            audioFiles.forEach(file => {// loop through each audio file
                formData.append('files', file);// add each audio file into our form
            });

            const headers = {
                'Content-Type': 'multipart/form-data', // specify the content type of the request
                'xi-api-key': apiKey,// eleven labs api key for authentication
            };

            return axios.post('https://api.elevenlabs.io/v1/voices/add', formData, { headers })// Return the promise from axios.post
                .then(response => {
                    return ["success", response]
                })
                .catch(error => {
                    return ["error", null]
                });

        } catch (e) {
            console.log(e)
            return "error"
        }
    }

    const TextToSpeechRequest = async (textin, voiceId) => {
        const url = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`; //url to api call
        const headers = {
            "Content-Type": "application/json",
            "xi-api-key": apiKey,
        };

        const data = {
            text: textin,
            voice_settings: {
                stability: stability, // this number is a percentage so must be less than 1
                similarity_boost: similarity,// this number is a percentage so must be less than 1
            },
        };

        const response = await fetch(url, {
            // response is the return data of the fetch
            method: "POST",
            headers,
            body: JSON.stringify(data),
        });

        try {
            const audioBlob = await response.blob(); // raw audio data
            const blob = new Blob([audioBlob], { type: "audio/mp3" });
            const audioUrl = URL.createObjectURL(blob);
            audioPlayerRef.current.src = audioUrl; // audio ref object holds the data from the audio blob
            setBlobURL(audioUrl);
            return "success"
        } catch (e) {
            console.log(e)
            return "failed"
        }
    };

    async function deleteVoice(voice_id) {// function for deleting the voice slot after use
        try {
            const options = { method: 'DELETE', headers: { 'xi-api-key': apiKey } };

            await fetch(`https://api.elevenlabs.io/v1/voices/${voice_id}`, options)
                .then(response => response.json())
                .then(response => console.log("Voice cycle completed!"))
                .catch(err => console.error(err));
            return 'success'
        } catch (e) {
            console.log(e)
            return 'failed'
        }
    }

    async function handleTextToSpeechLab(textin, voicePocketbaseID) {// function for performing entire request together 
        try {
            const getVoiceFilesResponse = await getVoiceFiles(voicePocketbaseID); // get the voice files
            if (getVoiceFilesResponse[0] !== "success") throw new Error("getVoiceFiles failed")
            const files = getVoiceFilesResponse[1];
            /////////////
            const createTempVoiceSlotResponse = await createTempVoiceSlot(voicePocketbaseID, files); //create the voice and train it with the voice files
            if (createTempVoiceSlotResponse[0] !== "success") throw new Error("createTempVoiceSlot did not succeed");// throw error
            /////////////
            const voice_id = createTempVoiceSlotResponse[1].data['voice_id'];// get the voice ID returned from 11 labs
            const TextToSpeechRequestResponse = await TextToSpeechRequest(textin, voice_id); // text to speech function that updates audio players refrence and the audio blob state
            if (TextToSpeechRequestResponse !== "success") throw new Error("TextToSpeechRequest failed")
            ///////////
            const deleteVoiceResponse = await deleteVoice(voice_id);// delete the voice in eleven labs after its been used
            if (deleteVoiceResponse !== 'success') throw new Error("deleteVoice Failed.")

        } catch (error) {
            console.error(error);
            throw new Error("Something went wrong inside handleTextToSpeechLab")
        }
    }

    return {
        handleTextToSpeechLab,
        audioPlayerRef,
        downloadAudio,
        blobURL
    }
}
