import pb from "UIM"

export default async function ClickedOnCelebMix() {
    const id = "qcnxryux77mxtyl"
    let clickCount = null
    const clickRecord = await pb.collection("celeb_mix_counter").getOne(id).then((res) => {
        clickCount = res['clicks']
        clickCount += 1
        const data = {
            clicks: clickCount,
        };
        pb.collection("celeb_mix_counter").update(id, data)
    })
}